import React from 'react';
import { Link } from 'gatsby';
import { genericHashLink } from 'react-router-hash-link';
import clx from 'classnames';

import { useAuthContext } from '../../../../data/context/authContext';

import BaseIcon from '../../../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import IconExit from '../../../_ui/_blocks/Icons/IconExit';
import IconClipboard from '../../../_ui/_blocks/Icons/IconClipboard';
import IconBasket from '../../../_ui/_blocks/Icons/IconBasket';
import IconTruck from '../../../_ui/_blocks/Icons/IconTruck';
import IconProfileLight from '../../../_ui/_blocks/Icons/IconProfileLight';
import IconHome from '../../../_ui/_blocks/Icons/Navigation/IconHome';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';

import * as styles from '../../CraftMenuTypes/SidebarNavigation/SidebarNavigation.module.css';

interface Props {
    className?: string;
}

const AccountMenu = ({ className }: Props) => {
    // @ts-ignore
    const ScrollLink = genericHashLink(Link);

    const { logout } = useAuthContext();

    const handleLogout = () => logout();

    const navClassName = clx(styles.nav, className);

    return (
        <nav className={navClassName}>
            <ul className={styles.list}>
                <li>
                    <Link to="/account" className={styles.link} activeClassName={styles.linkActive} title="Dashboard">
                        <BaseIcon>
                            <IconHome />
                        </BaseIcon>
                        Dashboard
                    </Link>
                </li>
                <li>
                    <Link to="/account/orders" className={styles.link} activeClassName={styles.linkActive} title="Orders" partiallyActive>
                        <BaseIcon>
                            <IconBasket />
                        </BaseIcon>
                        Orders
                    </Link>
                    <ul className={styles.submenu}>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/orders#order-history"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                partiallyActive
                                title="Order history"
                            >
                                View your order history
                            </ScrollLink>
                        </li>
                        <li>
                            <Link
                                to="/account/orders/treatment-reorders"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                partiallyActive
                                title="Treatment reorders"
                            >
                                Reorder a treatment
                            </Link>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/orders#saved-baskets"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                partiallyActive
                                title="Saved baskets"
                            >
                                Load a saved basket
                            </ScrollLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link
                        to="/account/delivery-addresses"
                        className={styles.link}
                        activeClassName={styles.linkActive}
                        title="Billing & delivery"
                        partiallyActive
                    >
                        <BaseIcon>
                            <IconTruck />
                        </BaseIcon>
                        Billing & delivery
                    </Link>
                    <ul className={styles.submenu}>
                        <li>
                            <Link
                                to="/account/delivery-addresses"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Delivery addresses"
                            >
                                Delivery addresses
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/account/saved-payment-methods"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Payment methods"
                            >
                                Payment methods
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link
                        to="/account/personal-medical-record"
                        className={styles.link}
                        activeClassName={styles.linkActive}
                        title="Personal Medical Record"
                        partiallyActive
                    >
                        <BaseIcon>
                            <IconClipboard />
                        </BaseIcon>
                        Personal Medical Record
                    </Link>
                    <ul className={styles.submenu}>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/personal-medical-record#personal-details"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Personal details"
                            >
                                Personal details
                            </ScrollLink>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/personal-medical-record#consultations"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Consultations"
                            >
                                Consultations
                            </ScrollLink>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/personal-medical-record#test-results"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Test results"
                            >
                                Test results
                            </ScrollLink>
                        </li>
                        <li>
                            {/* @ts-ignore */}
                            <ScrollLink
                                to="/account/personal-medical-record#prescriptions"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Prescriptions"
                            >
                                Prescription Requests
                            </ScrollLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link
                        to="/account/settings"
                        className={styles.link}
                        activeClassName={styles.linkActive}
                        title="Settings"
                        partiallyActive
                    >
                        <BaseIcon>
                            <IconProfileLight />
                        </BaseIcon>
                        Edit Details
                    </Link>
                    <ul className={styles.submenu}>
                        <li>
                            <Link
                                to="/account/settings"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Personal details"
                            >
                                Personal details
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/account/settings/weight-and-height"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Weight &amp; height"
                            >
                                Weight &amp; height
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/account/settings/password"
                                className={styles.sublink}
                                activeClassName={styles.sublinkActive}
                                title="Update password"
                            >
                                Update password
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Button variant="none" size="none" className={styles.link} title="Sign out of your account" onClick={handleLogout}>
                        <BaseIcon>
                            <IconExit />
                        </BaseIcon>
                        Sign out
                    </Button>
                </li>
            </ul>
        </nav>
    );
};

export default AccountMenu;
