import React from 'react';
import Typography from '@components/_ui/_blocks/Typography/Typography';
import useTreatmentImage from '../../../data/staticQueryHooks/useTreatmentImage';
import formatQuantityLabel from '../../Treatment/helpers/formatQuantityLabel';

import * as styles from './AccountTreatmentList.module.css';

export interface AccountTreatmentListProps {
    items: Array<any>;
    limit?: number | undefined;
    className?: string;
}

const AccountTreatmentList = ({ items, limit = undefined, className = '' }: AccountTreatmentListProps) => {
    const { getTreatmentImage } = useTreatmentImage();

    let listItems = items;
    if (limit) listItems = listItems.slice(0, limit);

    return (
        <ul className={className}>
            {listItems.map((item) => {
                const treatmentImage = getTreatmentImage(item.reference) || '/images/pill.svg';
                return (
                    <li className={styles.item} key={item.reference}>
                        <img src={treatmentImage} className={styles.image} alt={`The packaging of ${item.treatment}`} />
                        <div>
                            <Typography as="div" typeset="heading" size="080" lineHeight="1000">
                                {item.treatment}
                            </Typography>
                            <Typography as="div" typeset="heading" size="080" lineHeight="1000">
                                {formatQuantityLabel(item)}
                            </Typography>
                        </div>
                    </li>
                );
            })}
            {items.length > limit! ? (
                <li className={styles.item}>
                    <div className={styles.limit}>
                        <Typography typeset="heading" size="080" lineHeight="1000">
                            + {items.length - limit!}
                        </Typography>
                    </div>
                    <Typography typeset="heading" size="080" lineHeight="1000">
                        + {items.length - limit!} items
                    </Typography>
                </li>
            ) : null}
        </ul>
    );
};

export default AccountTreatmentList;
