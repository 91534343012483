import React, { useState, useEffect } from 'react';

import { useAuthContext } from '../../../data/context/authContext';
import { useAccountNavigationContext } from '../../../data/context/accountContext/accountNavigationContext';
import { emitNavigationOpenEvent } from '../../../hooks/useNavigationCloseHook';

import IconProfileLight from '../../_ui/_blocks/Icons/IconProfileLight';
import IconProfileDark from '../../_ui/_blocks/Icons/IconProfileDark';
import ButtonLink from '../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Button from '../../_ui/_blocks/Buttons/Button/Button';
import BaseIcon from '../../_ui/_blocks/Icons/BaseIcon/BaseIcon';

import * as styles from '../DrawerNavigation.module.css';

const SignInButton = () => {
    const { isLoggedIn, authedBefore: isReturningCustomer } = useAuthContext();
    const { setAccountNavIsOpen } = useAccountNavigationContext();

    // This will run client side and change the key of the component below. Reason for this is because using
    // loadable will cause a layout shift as the element pops into existence. Adding a fallback will
    // duplicate code not needed and using this component as a fallback doesn't work.
    // This is to change the button variant to primary when the user is a returning customer.
    const [isClient, setIsClient] = useState('server');
    useEffect(() => setIsClient('client'), []);

    const openAccountSidebar = () => {
        emitNavigationOpenEvent('account');
        setAccountNavIsOpen(true);
    };

    if (isLoggedIn) {
        return (
            <Button
                variant="none"
                size="none"
                className={styles.button}
                title="Your Independent Pharmacy account"
                aria-label="Your Independent Pharmacy account"
                onClick={openAccountSidebar}
            >
                Account
                <BaseIcon>
                    <IconProfileLight />
                </BaseIcon>
            </Button>
        );
    }

    return (
        <ButtonLink
            key={isClient}
            variant={isReturningCustomer ? 'primary' : 'none'}
            size="none"
            className={styles.button}
            aria-label="Your Independent Pharmacy account"
            title="Your Independent Pharmacy account"
            to="/login"
        >
            Sign In
            <BaseIcon>
                <IconProfileDark />
            </BaseIcon>
        </ButtonLink>
    );
};

export default SignInButton;
