import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';

import useTotalTreatmentAndConditionsCount from '../../../../../data/staticQueryHooks/useTotalTreatmentAndConditionCount';

import CraftMenuLink from '../../../CraftMenuLink/CraftMenuLink';
import ButtonLink from '../../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Typography from '../../../../_ui/_blocks/Typography/Typography';

import * as styles from './Megamenu.module.css';

interface Props {
    /** The navigation tree to be rendered */
    nodes: {
        id: string;
        title: string;
        level: string;
        url: string;
        parent: {
            id: string;
        };
        element: {
            slug: string;
        };
        type: string;
    }[];

    className: string;
}

const MegamenuBrands = ({ nodes = [], className = '' }: Props) => {
    const { treatmentCount, conditionCount } = useTotalTreatmentAndConditionsCount();

    const timeout = useRef<NodeJS.Timeout | undefined>();

    const handleButtonClick = () => {
        document.body.classList.add('_js-nav-megamenu-cancel');
        timeout.current = setTimeout(() => {
            document.body.classList.remove('_js-nav-megamenu-cancel');
        }, 2000);
    };

    useEffect(
        () => () => {
            // On unmount clear timeout
            if (timeout.current) {
                document.body.classList.remove('_js-nav-megamenu-cancel');
                clearTimeout(timeout.current);
            }
        },
        []
    );

    return (
        <div className={`flex ${styles.megamenu} ${className}`}>
            <div className="lg:min-w-[30rem] space-y-100 p-200">
                <div className="flex justify-between items-center">
                    <Typography typeset="heading" size="200">
                        Popular Brands
                    </Typography>
                    <Link
                        to="/brands"
                        title="View all brands"
                        className="p-050 -m-050 type-paragraph underline hover:no-underline text-content-highlight"
                        onClick={handleButtonClick}
                    >
                        View all
                    </Link>
                </div>
                <ul className="grid grid-cols-2 gap-100">
                    {nodes.map((node) => {
                        Object.assign(node, { url: node.url.replace('brand/', 'brands/') });
                        return (
                            <li key={node.id}>
                                <CraftMenuLink
                                    title={`See all ${node.title} Treatments`}
                                    menuNode={node}
                                    className={`${styles.megamenuGroupHeader} type-paragraph type-090 gap-0`}
                                >
                                    <div className="flex-1">{node.title} </div>
                                </CraftMenuLink>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className={styles.conditionOffers}>
                <Typography as="div" typeset="heading">
                    We offer {treatmentCount} over-the-counter and prescription
                    <br /> medicines, for the treatment of {conditionCount} conditions.
                </Typography>
                <Typography as="div" color="quiet" className="mt-050">
                    We also provide expert advice on a range
                    <br /> of common health issues.
                </Typography>
                <ButtonLink
                    variant="primary"
                    to="/treatments"
                    title="Find your treatment"
                    onClick={handleButtonClick}
                    className="mt-100 text-center mx-auto"
                >
                    Find your treatment
                </ButtonLink>
            </div>
        </div>
    );
};

export default MegamenuBrands;
