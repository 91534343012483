import Order from '../../../../../types/api/order/Order';
import { BloomreachBasketItem, BloomreachConversionPageView } from '../types/bloomreachPixelTypes';

export const brFormatOrderToConversion = (order: Order): Omit<BloomreachConversionPageView, 'ptype'> => {
    const items: BloomreachBasketItem[] = order.items.map((orderItem) => ({
        prod_id: orderItem?.parent?.reference || orderItem.reference,
        sku: orderItem.reference,
        name: orderItem.treatment,
        quantity: orderItem.quantity,
        price: parseFloat((orderItem.unit_cost / 100).toFixed(2)),
    }));

    return {
        basket: { items },
        basket_value: parseFloat((order.total / 100).toFixed(2)),
        currency: 'GBP',
        is_conversion: '1',
        order_id: order.uuid,
    };
};
