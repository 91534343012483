// extracted by mini-css-extract-plugin
export var bestSeller = "bestSeller_uQhV9N9zD-";
export var borderRadiusFull = "borderRadiusFull_T+tFEwJCqz";
export var borderRadiusSmall = "borderRadiusSmall_gD925IhIwU";
export var bundlePack = "bundlePack_Lju2NlZOUZ";
export var error = "error_7rkABv+jCw";
export var info = "info_5P5+UhxFa2";
export var large = "large_GZkm9ENke4";
export var medium = "medium_d+zJV+3eFI";
export var multiBuyOffer = "multiBuyOffer_hgkEQysF54";
export var newPackaging = "newPackaging_B5zajHFBVN";
export var newProduct = "newProduct_OWoxpaTyQa";
export var regular = "regular_TNO4tYYGvA";
export var sale = "sale_j4rXC-8cR2";
export var specialOffer = "specialOffer_MuS8XVyGH1";
export var success = "success_+RgYh6kEaV";
export var tag = "tag_8nWbc6FxY3";
export var warning = "warning_qPIKwQaEhM";