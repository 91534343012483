import React from 'react';
import loadable from '@loadable/component';

import { useAssessmentContext } from '@data/context/assessmentContext/assessmentContext';

import Modal from '@components/_ui/Modal/Modal';

const Assessment = loadable(() => import('./Assessment'));

/**
 * Component for hosting the assessment files. Only should lazy load in the assessment files in
 * once an assessment has been clicked.
 */
const AssessmentWrapper = () => {
    const { treatmentName, closeAssessment } = useAssessmentContext();

    return (
        <Modal show={!!treatmentName} onClose={closeAssessment} className="container-large w-full h-full" color="canvas">
            <Assessment />
        </Modal>
    );
};

export default AssessmentWrapper;
