/* eslint-disable import/no-relative-packages */

import { localStorageGet, localStorageSet } from '../../../src/hooks/useLocalStorageSync';
import config from '../config/config';
import PluginOptions from '../types/PluginOptions';

const initSentry = (pluginOptions: PluginOptions) => {
    const onScriptLoad = () => {
        if (typeof window === 'undefined' || !window.Sentry || !pluginOptions.dsn) {
            console.warn('Sentry is not loaded or dsn is not provided');
            return;
        }

        const sentryConfig = {
            ...config(),
            ...pluginOptions,
        };

        sentryConfig.integrations = [window.Sentry.browserTracingIntegration()];

        window.Sentry.init(sentryConfig);

        if (window.localStorage) {
            // ssid = sentry session id (created and stored in local storage in our custom sentry plugin to distinguish the user from another user)
            let sentrySessionId = localStorageGet('ssid') || null;

            if (!sentrySessionId) {
                sentrySessionId = Math.random().toString(36).substr(2, 9);
                localStorageSet('ssid', sentrySessionId);
            }

            window.Sentry.configureScope((scope: any) => {
                scope.setTag('transaction_id', sentrySessionId);
            });

            window.Sentry.setUser({ id: sentrySessionId, username: sentrySessionId });
        }
    };

    let tickCount = 0;
    const intervalId = setInterval(() => {
        tickCount += 1;
        if (typeof window !== 'undefined' && window.Sentry && pluginOptions.dsn) {
            clearInterval(intervalId);
            onScriptLoad();
        }
    }, 500);

    setTimeout(() => {
        clearInterval(intervalId);
        if (tickCount >= 20) {
            console.warn('Timeout: Sentry or pluginOptions not available');
        }
    }, 10000);
};

export default initSentry;
