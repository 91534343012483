import React, { useEffect } from 'react';

import { useAccountNavigationContext } from '../../data/context/accountContext/accountNavigationContext';
import { useSidebarNavigationUpdaterContext } from '../../data/context/sidebarNavigationContext';

import Flyout from '../_ui/Flyout/Flyout';
import AccountMenu from './AccountNavigation/AccountMenu/AccountMenu';

const AccountNavigation = () => {
    const setSidebarNavIsOpen = useSidebarNavigationUpdaterContext();
    const { accountNavIsOpen, setAccountNavIsOpen } = useAccountNavigationContext();

    const handleBackToMenu = () => {
        setSidebarNavIsOpen(true);
        setAccountNavIsOpen(false);
    };

    const handleCloseNavigation = () => {
        setAccountNavIsOpen(false);
    };

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        window.addEventListener('routeUpdate', handleCloseNavigation);
        return () => window.removeEventListener('routeUpdate', handleCloseNavigation); // eslint-disable-line consistent-return
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Flyout isOpen={accountNavIsOpen} onBack={handleBackToMenu} onClose={handleCloseNavigation} title="Back to menu">
            <AccountMenu />
        </Flyout>
    );
};

export default AccountNavigation;
