import React from 'react';

import { useAuthContext } from '../../../data/context/authContext';
import { SESSION_FLASH_MESSAGE_KEY } from '../../../modules/auth/auth';
import FlashMessage from '../../FlashMessage/FlashMessage';
import useSessionActivity from './hooks/useSessionActivity';

const SessionActivity = () => {
    const { isLoggedIn, logout } = useAuthContext();

    useSessionActivity(isLoggedIn, logout);

    return <FlashMessage important cookieKey={SESSION_FLASH_MESSAGE_KEY} className="max-w-[30rem]" />;
};

export default SessionActivity;
