import React, { useEffect, Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import clx from 'classnames';

import { useAuthContext } from '../../../../data/context/authContext';
import { useSidebarNavigationValueContext, useSidebarNavigationUpdaterContext } from '../../../../data/context/sidebarNavigationContext';
import { useAccountNavigationContext } from '../../../../data/context/accountContext/accountNavigationContext';
import findIcon from '../../../_ui/_blocks/Icons/helpers/findIcon';

import Flyout from '../../../_ui/Flyout/Flyout';
import IconChevronRight from '../../../_ui/_blocks/Icons/Navigation/IconChevronRight';
import BaseIcon from '../../../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import IconHome from '../../../_ui/_blocks/Icons/Navigation/IconHome';
import IconExit from '../../../_ui/_blocks/Icons/IconExit';
import CraftMenuLink, { MenuNode } from '../../CraftMenuLink/CraftMenuLink';
import IconProfileLight from '../../../_ui/_blocks/Icons/IconProfileLight';
import ButtonLink from '../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import AccountNavigation from '../../AccountNavigation';

import * as styles from './SidebarNavigation.module.css';

/**
 * Mobile navigation
 */
const SidebarNavigation = () => {
    const { isLoggedIn, logout } = useAuthContext();

    const sidebarNavIsOpen = useSidebarNavigationValueContext();
    const setSidebarNavIsOpen = useSidebarNavigationUpdaterContext();
    const { setAccountNavIsOpen } = useAccountNavigationContext();

    const data = useStaticQuery(graphql`
        {
            craftcms {
                links: nodes(navHandle: "mobileNavigation", level: 1) {
                    ...sidebarLinks
                }
            }
        }
    `);

    const { links } = data.craftcms;

    const handleLogout = () => logout();
    const closeNavigation = () => setSidebarNavIsOpen(false);
    const handleAccountClick = () => {
        closeNavigation();
        setAccountNavIsOpen(true);
    };

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        window.addEventListener('routeUpdate', closeNavigation);
        return () => window.removeEventListener('routeUpdate', closeNavigation); // eslint-disable-line consistent-return

        // closeNavigation doesn't change, so no need to add it to the deps array
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const navClassName = clx(styles.nav, {
        [styles.navBottomPadding]: !isLoggedIn,
    });

    return (
        <Fragment>
            <Flyout isOpen={sidebarNavIsOpen} title="Menu" onClose={closeNavigation}>
                <nav className={navClassName}>
                    <ul className={styles.list}>
                        <li>
                            <Link to="/" className={styles.link} title="Home" activeClassName={styles.linkActive}>
                                <BaseIcon>
                                    <IconHome />
                                </BaseIcon>
                                Home
                            </Link>
                        </li>
                        {isLoggedIn ? (
                            <li>
                                <button type="button" className={styles.link} onClick={handleAccountClick}>
                                    <BaseIcon>
                                        <IconProfileLight />
                                    </BaseIcon>
                                    Your account
                                    <BaseIcon size="xsmall" className="ml-auto">
                                        <IconChevronRight />
                                    </BaseIcon>
                                </button>
                            </li>
                        ) : null}
                        {links.map((link: MenuNode) => (
                            <li key={link.id}>
                                <CraftMenuLink
                                    className={styles.link}
                                    title={link.title}
                                    menuNode={link}
                                    activeClassName={styles.linkActive}
                                >
                                    <BaseIcon>{findIcon(`icon-${link.navigationIcon[0]}`)}</BaseIcon>
                                    {link.title}
                                </CraftMenuLink>
                                {link.children.length ? (
                                    <ul className={styles.submenu}>
                                        {link.children.map((child: MenuNode['children'][number]) => (
                                            <li key={child.id}>
                                                <CraftMenuLink
                                                    menuNode={child}
                                                    className={styles.sublink}
                                                    activeClassName={styles.sublinkActive}
                                                >
                                                    {child.title}
                                                </CraftMenuLink>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        ))}
                        {isLoggedIn ? (
                            <li>
                                <Button
                                    variant="none"
                                    size="none"
                                    className={styles.link}
                                    title="Sign out of your account"
                                    onClick={handleLogout}
                                >
                                    <BaseIcon>
                                        <IconExit />
                                    </BaseIcon>
                                    Sign out
                                </Button>
                            </li>
                        ) : (
                            <li className="!border-t-0 !p-0">
                                <ButtonLink className={styles.signIn} to="/login" title="Signin to your account">
                                    Sign In
                                </ButtonLink>
                            </li>
                        )}
                    </ul>
                </nav>
            </Flyout>
            {isLoggedIn ? <AccountNavigation /> : null}
        </Fragment>
    );
};

export default SidebarNavigation;
