import React from 'react';

import Attention from '../../../../_ui/_blocks/Attention/Attention';
import { Product } from '../../../../../types/api/products/Product';

import * as styles from './OdonlyNotification.module.css';

interface Props {
    conditionName: Product['condition_name'];
}

/**
 * Notification to the user to inform them that they will need to complete an assessment before they can get
 * an ODONLY treatment added to the basket.
 */
const OdonlyNotification = ({ conditionName }: Props) => (
    <Attention
        heading="Don't wait to get the treatment you need"
        type="infoMute"
        icon="medicalCross"
        className={styles.OdonlyNotificationCallout}
    >
        <p>
            Begin your journey to treat {conditionName} with our online assessment. Once completed, you can review and select from the most
            suitable treatment options.
        </p>
    </Attention>
);

export default OdonlyNotification;
