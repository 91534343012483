import { BloomreachPageType } from '../types/bloomreachPixelTypes';

export interface BloomreachExpectedPageContext {
    // Depending on what page we're on these might not be passed into the pageContext
    sectionHandle?: string;
    id?: string;
}

/**
 * We generate the page type for bloomreach based upon the path and the page context section handle
 * the ptype can also be overridden using the component in page.
 * @param path
 * @param pageContext
 * @returns
 */
export const getPageType = (path: string, pageContext: BloomreachExpectedPageContext): BloomreachPageType => {
    if (path === '/') return 'homepage';
    if (path === '/search-results') return 'search';
    if (pageContext?.sectionHandle === 'treatments') return 'product';
    if (pageContext?.sectionHandle === 'conditions') return 'category';

    return 'other';
};
