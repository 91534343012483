/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState } from 'react';
import { useNavigationCloseHook } from '../../hooks/useNavigationCloseHook';

/** Start of the basket context. */
const NavigationValueContext = createContext();
const NavigationUpdateContext = createContext();

/**
 * This provider handles all of our search state. Wrapping a new provider will create a new scoped-state.
 * Meaning you can have multiple individual searches on a page. Useful for in page results vs overlay results.
 * @param {*} props
 */
export const SidebarNavigationProvider = ({ children }) => {
    const [sidebarNavIsOpen, setSidebarNavIsOpen] = useState(false);

    useNavigationCloseHook('mobile', () => setSidebarNavIsOpen(false));

    return (
        <NavigationValueContext.Provider value={sidebarNavIsOpen}>
            <NavigationUpdateContext.Provider value={setSidebarNavIsOpen}>{children}</NavigationUpdateContext.Provider>
        </NavigationValueContext.Provider>
    );
};

export const useSidebarNavigationValueContext = () => {
    const sidebarNavIsOpen = useContext(NavigationValueContext);

    if (sidebarNavIsOpen === undefined) {
        throw new Error('useSidebarNavigationValueContext must be used within a SidebarNavigationProvider');
    }

    return sidebarNavIsOpen;
};

export const useSidebarNavigationUpdaterContext = () => {
    const setSidebarNavIsOpen = useContext(NavigationUpdateContext);

    if (setSidebarNavIsOpen === undefined) {
        throw new Error('useSidebarNavigationUpdaterContext must be used within a SidebarNavigationProvider');
    }

    return setSidebarNavIsOpen;
};
