// extracted by mini-css-extract-plugin
export var company_information = "company_information_Z-O06Cn-Dv";
export var container = "container_AUhvZP84rK";
export var fluid = "fluid_KJmt+Ao6J2";
export var footer = "footer_UZpYCUlNUv";
export var footer_company_information = "footer_company_information_L2n1jXK53B";
export var footer_pharmacist = "footer_pharmacist_d6AldxV-7z";
export var footer_section = "footer_section_wswfzuNQzI";
export var hippo_footer_socials = "hippo_footer_socials_V1BukZNQTp";
export var logo = "logo_ADEigPyBXP";
export var space_y_200 = "space_y_200_4UXUcQ43g4";