/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react';
import BloomreachAnalytics from '../../BloomreachAnalytics';

/**
 * This hook is used to start the exponea tracking on page refresh.
 * We need to wait for the exponea script to be loaded before we can start the tracking.
 * This hook is used in the BloomreachScriptTag component.
 */
const useStartExponea = () => {
    const isBloomreachAnalyticsEnabled = BloomreachAnalytics.isEnabled(); // this is specifically for if the user has said yes to cookies + its enabled in the env

    const animationFrameRef = useRef<number | null>(null);

    useEffect(() => {
        // if we have already said yes to cookies and bloomreach is enabled, start the exponea tracking (on page refresh)
        if (!isBloomreachAnalyticsEnabled) return;

        const waitForExponeaScript = () => {
            if (BloomreachAnalytics.hasExponeaBeenStarted) return;
            if (BloomreachAnalytics.checkExponea()) {
                BloomreachAnalytics.startExponea();
                cancelAnimationFrame(animationFrameRef.current!);
            } else {
                // defer it so it doesnt run 100000 times per second
                setTimeout(() => {
                    animationFrameRef.current = requestAnimationFrame(waitForExponeaScript);
                }, 100);
            }
        };

        waitForExponeaScript();

        return () => {
            cancelAnimationFrame(animationFrameRef.current!);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useStartExponea;
