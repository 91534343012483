import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Typography from '@components/_ui/_blocks/Typography/Typography';
import CraftMenuLink, { MenuNode } from '../../CraftMenuLink/CraftMenuLink';

import * as styles from '../../../Footer/Footer.module.css';

/** Popular conditions menu that displays in the footer. */
const FooterPopularConditions = () => {
    const data = useStaticQuery(graphql`
        {
            craftcms {
                popular: nodes(navHandle: "footerPopularConditions", level: 1) {
                    ...footerLinkShape
                }
            }
        }
    `);

    const { popular } = data.craftcms;

    return popular.length ? (
        <div className={styles.footer_section}>
            <Typography typeset="heading" size="090">
                Popular Conditions
            </Typography>
            <ul>
                {popular.map((link: MenuNode, index: number) => (
                    <li key={`footer-popular-conditions-${index}`}>
                        <Typography typeset="paragraph" size="090">
                            <CraftMenuLink menuNode={link}>{link.title}</CraftMenuLink>
                        </Typography>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
};

export default FooterPopularConditions;
