import React, { Fragment } from 'react';

const IconBin = () => (
    <Fragment>
        <path d="M17.4985 13.4445C17.4678 12.8931 16.996 12.4709 16.4445 12.5015C15.8931 12.5322 15.4709 13.004 15.5015 13.5555L17.0015 40.5555C17.0322 41.1069 17.504 41.5291 18.0555 41.4985C18.6069 41.4678 19.0291 40.996 18.9985 40.4445L17.4985 13.4445Z" />
        <path d="M32.4985 13.5555C32.5291 13.004 32.1069 12.5322 31.5555 12.5015C31.004 12.4709 30.5322 12.8931 30.5015 13.4445L29.0015 40.4445C28.9709 40.996 29.3931 41.4678 29.9445 41.4985C30.496 41.5291 30.9678 41.1069 30.9985 40.5555L32.4985 13.5555Z" />
        <path d="M25 13.5C25 12.9477 24.5523 12.5 24 12.5C23.4477 12.5 23 12.9477 23 13.5V40.5C23 41.0523 23.4477 41.5 24 41.5C24.5523 41.5 25 41.0523 25 40.5V13.5Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33 4.425V6H43.5C44.3284 6 45 6.67157 45 7.5C45 8.32843 44.3284 9 43.5 9H40.7855L38.6989 42.8886C38.6636 44.2324 38.1142 45.5119 37.163 46.463C36.2039 47.4222 34.9108 47.9728 33.5547 47.9997L33.525 48H14.475L14.4453 47.9997C13.0892 47.9728 11.7961 47.4222 10.837 46.463C9.88583 45.5119 9.33636 44.2323 9.30107 42.8886L7.21452 9H4.5C3.67157 9 3 8.32843 3 7.5C3 6.67157 3.67157 6 4.5 6H15V4.425C15 3.25142 15.4662 2.1259 16.2961 1.29605C17.1259 0.466204 18.2514 0 19.425 0H28.575C29.1561 0 29.7315 0.114456 30.2684 0.336833C30.8052 0.55921 31.293 0.885153 31.7039 1.29605C32.1148 1.70695 32.4408 2.19476 32.6632 2.73163C32.8855 3.26849 33 3.8439 33 4.425ZM18.4174 3.41737C18.6846 3.15013 19.0471 3 19.425 3H28.575C28.7621 3 28.9474 3.03686 29.1203 3.10847C29.2932 3.18008 29.4503 3.28505 29.5826 3.41737C29.7149 3.5497 29.8199 3.70679 29.8915 3.87968C29.9631 4.05257 30 4.23787 30 4.425V6H18V4.425C18 4.04707 18.1501 3.68461 18.4174 3.41737ZM37.7798 9L35.7028 42.7328C35.7016 42.7536 35.7007 42.7745 35.7003 42.7953C35.6888 43.3765 35.4528 43.9307 35.0417 44.3417C34.6337 44.7497 34.0847 44.9853 33.5082 45H14.4918C13.9153 44.9853 13.3663 44.7497 12.9583 44.3417C12.5472 43.9307 12.3112 43.3765 12.2997 42.7953C12.2993 42.7745 12.2984 42.7536 12.2972 42.7328L10.2202 9H37.7798Z"
        />
    </Fragment>
);

export default IconBin;
