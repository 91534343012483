export const fontSizes = {
    '070': 'type-070',
    '080': 'type-080',
    '090': 'type-090',
    '100': 'type-100',
    '200': 'type-200',
    '300': 'type-300',
    '400': 'type-400',
    '500': 'type-500',
    '600': 'type-600',
    '700': 'type-700',
    '800': 'type-800',
    '900': 'type-900',
    '1000': 'type-1000',
};

export const getFontSizeClass = (fontSize?: keyof typeof fontSizes) => (fontSize ? fontSizes[fontSize] : '');
