import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

interface ImagePreloadProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    renderImage?: boolean;
    src: string;
    alt: string;
    width?: number | string;
    height?: number | string;
    className?: string;
}

const ImagePreload = ({ src, alt, renderImage = true, ...rest }: ImagePreloadProps) => (
    <Fragment>
        <Helmet>
            <link rel="preload" href={src} as="image" />
        </Helmet>
        {renderImage && <img src={src} alt={alt} {...rest} />}
    </Fragment>
);

export default ImagePreload;
