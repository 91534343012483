import Sanitise from '../helpers/Sanitise/Sanitise';
import sanitiseBreadcrumb from '../helpers/sanitiseBreadcrumb/sanitiseBreadcrumb';
import Config from '../types/Config';

export const SENTRY_SRC = '/third-party/sentry/7.107.0/bundle.tracing.min.js';
export const SENTRY_PREFETCH_DNS = 'https://browser.sentry-cdn.com/';

const config = (): Config => {
    const integrations: Config['integrations'] = [];

    return {
        dsn: undefined,
        sampleRate: undefined, // We want 100% of these errors.
        environment: undefined,
        sendDefaultPii: true,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        integrations,
        tracesSampleRate: 0.1, // We only want to track 10-20% of these. as this will happen on every page load. // TODO: This should be configurable.
        tracePropagationTargets: [
            '/^https:\\/\\/staging\\.(www|api)\\.theindependentpharmacy\\.co\\.uk(\\/.*)?$/',
            '/^https:\\/\\/(www|api)\\.theindependentpharmacy\\.co\\.uk(\\/.*)?$/',
        ], // TODO: This should be configurable.
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            'Loading chunk',
            'ChunkLoadError',
        ],
        beforeSend: (event) => new Sanitise(event).result(),
        beforeBreadcrumb: (breadcrumb) => sanitiseBreadcrumb(breadcrumb),
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Bloomreach
            /analytics.staging.theindependentpharmacy.co.uk/i,
            /analytics.theindependentpharmacy.co.uk/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
    };
};

export default config;
