import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import * as styles from './NumberIncrement.module.css';

interface Props {
    number: number;
}

/**
 * Increments number up and down with slide effect animation.
 */
const NumberIncrement = ({ number }: Props) => {
    const [currentNumber, setCurrentNumber] = useState(number);

    const timeout = useRef<NodeJS.Timeout | undefined>();

    /**
     * Checks the number passed in is a number and has changed.
     */
    let animationDirection = '';
    if (typeof number === 'number' && number !== currentNumber) {
        /**
         * If the new number is greater than the number stored in the state, set the css animation
         * class to number-increment--up, else animate clas should be number-increment--down.
         */
        animationDirection = number > currentNumber ? styles.animateUp : styles.animateDown;
    }

    /**
     * On change of the number passed in, set a timeout to allow time for the css animation to
     * change to the correct number, then update the state to the new number.
     *
     * !important -- Must update css when changing set timeout. 300ms matches css animation
     * found on ".number-increment__number".
     */
    useEffect(() => {
        timeout.current = setTimeout(() => setCurrentNumber(number), 300);

        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        };
    }, [number]);

    return (
        <div className={`${styles.incrementor} ${animationDirection}`}>
            <div className={styles.number}>
                <span className={styles.text}>{currentNumber + 1}</span>
            </div>
            <div className={styles.number}>
                <span className={styles.text}>{currentNumber}</span>
            </div>
            <div className={styles.number}>
                <span className={styles.text}>{currentNumber - 1}</span>
            </div>
        </div>
    );
};

NumberIncrement.defaultProps = {
    number: 0,
};

NumberIncrement.propTypes = {
    number: PropTypes.number,
};

export default NumberIncrement;
