import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Navigation/Header/Header';
import SessionActivity from '../../components/Authentication/SessionActivity/SessionActivity';
import AffiliateBanner from '../../modules/affiliate/components/AffiliateBanner';

const CheckoutLayout = ({ children }) => (
    <Fragment>
        <Helmet>
            <title>Checkout</title>
            <meta
                name="description"
                content="Online Pharmacy, Online Chemist & Online Doctor. We provide medicines, health & beauty, supplements & much more at great prices."
            />
        </Helmet>
        <AffiliateBanner />
        <Header disableSearch disableBasket disableBurgerMenu disableMainNav />
        <main>{children}</main>
        <Footer />
        <SessionActivity />
    </Fragment>
);

CheckoutLayout.defaultProps = {
    entryMeta: undefined,
};

CheckoutLayout.propTypes = {
    /** Contains all the entry meta data */
    entryMeta: PropTypes.shape({
        /** The text for the title tag of the page */
        metaTitle: PropTypes.string,
        /** The meta description of the page */
        metaDescription: PropTypes.string,
        /** The seo url */
        canonicalUrl: PropTypes.string,
        /** The text for the title tag of the facebook share card */
        openGraphTitle: PropTypes.string,
        /** The meta description of the page */
        openGraphDescription: PropTypes.string,
        /** String of url path to open graph image */
        openGraphImage: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
            })
        ),
        twitterCardTitle: PropTypes.string,
        twitterCardDescription: PropTypes.string,
        twitterCreator: PropTypes.string,
        twitterSite: PropTypes.string,
        twitterImage: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
            })
        ),
    }),
    /** The page content components */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default CheckoutLayout;
