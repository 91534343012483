import React, { ReactNode } from 'react';

import sendSentryMessage from '../../helpers/sendSentryMessage';
import IconCross from '../_ui/_blocks/Icons/Navigation/IconCross';
import BaseIcon from '../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import Attention from '../_ui/_blocks/Attention/Attention';
import Card from '../_ui/_blocks/Card/Card';
import Typography from '../_ui/_blocks/Typography/Typography';
import Button from '../_ui/_blocks/Buttons/Button/Button';

interface ModalErrorBoundaryProps {
    children: ReactNode;
    onClose: () => void;
}

interface ModalErrorBoundaryState {
    hasError: boolean;
    errorMessage: string | null;
}

export default class ModalErrorBoundary extends React.Component<ModalErrorBoundaryProps, ModalErrorBoundaryState> {
    constructor(props: ModalErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, errorMessage: null };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // You can also log the error to an error reporting service
        sendSentryMessage(error, (scope) => {
            scope.setExtra('errorInfo', errorInfo);
        });
    }

    render() {
        const { errorMessage, hasError } = this.state;
        const { children, onClose } = this.props;

        // You can render any custom fallback UI
        if (hasError) {
            return (
                <Card as="div" className="space-y-200">
                    <header className="space-y-050">
                        <div className="flex justify-between items-center">
                            <Typography as="h3" typeset="heading" size="100">
                                Error
                            </Typography>
                            <button type="button" onClick={onClose} title="Close treatment refill" className="p-025 -m-025">
                                <BaseIcon size="small">
                                    <IconCross />
                                </BaseIcon>
                            </button>
                        </div>
                    </header>
                    <Attention type="info" heading="There was an unexpected error">
                        <Typography as="p" size="090" color="quiet">
                            {errorMessage} Please contact support.
                        </Typography>
                    </Attention>
                    <Button size="small" title="close" type="button" className="block ml-auto" onClick={onClose}>
                        Close
                    </Button>
                </Card>
            );
        }

        return children;
    }
}
