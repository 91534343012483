/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';

import basketService from '../../services/basket.service';
import useDataApi from '../../../hooks/useDataApi';
import useDataApiEffect from '../../../hooks/useDataApiEffect';
import usePollingHook from '../../../hooks/usePollinghook';
import { useNavigationCloseHook } from '../../../hooks/useNavigationCloseHook';
import useLocalStorageSync, {
    localStorageGet,
    localStorageRemove,
    localStorageSet,
    useLocalStorageWatcher,
} from '../../../hooks/useLocalStorageSync';
import { useAuthContext } from '../authContext';
import { useConfirmationContext } from '../confirmationContext';

import AccountTreatmentList from '../../../components/Account/AccountTreatmentList/AccountTreatmentList';
import Typography from '../../../components/_ui/_blocks/Typography/Typography';
import Hr from '../../../components/_ui/_blocks/Hr/Hr';

/**
 * Because treatment was changed to treatmentId, we need to loop through a basket and treatmentId
 * basket.item.consultation.treatmentId.
 */
export const formatBasketConsultationIds = (basket: { items: any[] }) => {
    if (basket && basket.items) {
        basket.items.forEach((basketItem: { consultation: { treatment: any; treatmentId: any } }) => {
            if (basketItem.consultation && basketItem.consultation.treatment) {
                basketItem.consultation.treatmentId = basketItem.consultation.treatment; // eslint-disable-line no-param-reassign
            }
        });
    }

    return basket;
};

type deleteBasketFunction = (uuid: any, callback: null | (() => void)) => Promise<void>;

/** Start of the basket context. */
const BasketContext = createContext({});

interface BasketProviderProps {
    /** sets the default basket, this normally comes from local storage in implementation. */
    defaultBasket?:
        | {
              [key: string]: any;
          }
        | null
        | undefined;
}

/**
 * TODO: Refactor this file to use dataApi.
 * This provider handles all of our search state. Wrapping a new provider will create a new
 * scoped-state.
 * Meaning you can have multiple individual searches on a page. Useful for inpage results vs
 * overlay results.
 *
 * @param {defaultBasket, *} props
 */
export const BasketProvider = ({ ...props }: BasketProviderProps) => {
    const { isLoggedIn } = useAuthContext();
    const { openConfirmation, closeConfirmation } = useConfirmationContext();

    const setBasket = (b: any) => {
        localStorageSet('basket', formatBasketConsultationIds(b));
    };

    const basket = useLocalStorageWatcher('basket');

    const basketUuid = basket ? basket.uuid : null;

    const [basketLastUpdated, setBasketLastUpdated] = useState(localStorageGet('last_seen'));
    const [basketIsOpen, setBasketIsOpen] = useState(false);

    useNavigationCloseHook('basket', () => setBasketIsOpen(false));

    /**
     * Clears the local storage basket information.
     */
    const clearBasket = useCallback(() => {
        localStorageRemove('basket');
        localStorageRemove('last_seen');
    }, []);

    /**
     * If user is not logged in and a basket is found in local storage, clear the basket.
     */
    useEffect(() => {
        if (!isLoggedIn && basket) clearBasket();
    }, [isLoggedIn, basket, clearBasket]);

    /**
     * Calls the deleteBasket API.
     */
    const [{ isLoading: isDeletingBasket, error: deleteBasketError }, callDeleteBasket] = useDataApi(basketService.deleteBasket);

    /**
     * Calls the loadBasket API.
     */
    const [{ isLoading: isLoadingBasket, error: loadBasketError }, callLoadBasket] = useDataApi(basketService.loadBasket);

    /**
     * Runs initial basket API request on load and returns error, loading and response states to
     * get the current active basket from a user.
     */
    const getInitialBasket = useCallback(() => (isLoggedIn ? basketService.getActiveBasket() : {}), [isLoggedIn]);

    const [{ data: initialBasketData, isLoading: isLoadingInitialBasket, isError: initialBasketError, status: initialBasketStatus }] =
        useDataApiEffect(getInitialBasket, [getInitialBasket]);

    /**
     * Sends a request to the polling API every 30 seconds to get the latest active basket data.
     * Provides polling function that is added to the usePollingHook further down. Also provides
     * error, loading and response states.
     */
    const pollBasket = useCallback(async () => {
        if (!basketUuid || !basketLastUpdated) return;

        let response = null;

        try {
            response = await basketService.pollBasket(basketUuid, { last_seen: basketLastUpdated });
        } catch (e) {
            clearBasket();
            return;
        }

        if (!response) {
            clearBasket();
            return;
        }

        /* eslint-disable indent, no-fallthrough, no-useless-return */
        switch (response.status) {
            case 204: // No content - basket has been deleted, basically a 404, so clear the basket.
                clearBasket();
                return;
            case 205: // Reset content - basket has been updated since last check, so update the state.
                setBasketLastUpdated(dayjs().format('YYYY-MM-DD HH:mm:ss'));
                setBasket(response.data.data);
            case 200: // OK - basket has not been updated since last check, do nothing.
            default:
                return;
        }
    }, [basketUuid, basketLastUpdated, clearBasket]);

    /**
     * Allows us to save a basket using the useDataApi.
     */
    const [{ isLoading: saveBasketLoading }, callSaveBasket] = useDataApi(basketService.saveBasket);

    const saveBasket = async () => {
        const response = await callSaveBasket(basketUuid);
        if (response) clearBasket();
        return response;
    };

    /**
     * Provides polling function that is added to the usePollingHook further down. Also provides
     * error, loading and response states.
     */
    const [{ isLoading: updateBasketLoading }, updateBasket] = useDataApi(basketService.updateBasket);

    /**
     * Loads a spesific basket by setting it as active.
     * @param {string} uuid - Unique ID of the basket to load.
     */
    const loadBasket = async (basketToLoad: { uuid: null; items: string | any[] }) => {
        if (!basketToLoad || !basketToLoad.uuid || basketToLoad.uuid === basketUuid) return;

        const loadTheBasket = async () => {
            closeConfirmation();

            const response = await callLoadBasket(basketToLoad.uuid);

            if (!response) {
                alert('Basket could not be loaded because of an API error. Please try again or contact support.');
                return;
            }

            setBasket(response.data.data);
            if (typeof window !== 'undefined') window.location.href = '/checkout';
        };

        if (!basketUuid) {
            loadTheBasket();
            return;
        }

        openConfirmation({
            type: 'alert',
            severity: 'warning',
            heading: 'There are already items in your basket',
            children: basketToLoad ? (
                <div className="space-y-100">
                    <Typography as="p" color="quiet" size="090">
                        Loading a basket will replace any items currently in your basket.
                    </Typography>
                    <div className="space-y-100">
                        <Typography typeset="heading" size="090">
                            {basket.items.length} item{basket.items.length === 1 ? '' : 's'} will be removed:
                        </Typography>
                        <AccountTreatmentList items={basket.items} className="space-y-050" />
                    </div>
                    <Hr />
                    <div className="space-y-100">
                        <Typography typeset="heading" size="090">
                            {basketToLoad.items.length} item{basketToLoad.items.length === 1 ? '' : 's'} will be added:
                        </Typography>
                        {/* @ts-expect-error */}
                        <AccountTreatmentList items={basketToLoad.items} className="space-y-050" />
                    </div>
                </div>
            ) : (
                <Typography as="p" color="quiet">
                    Loading this basket will replace all items currently in your basket with the items in this basket. Are you sure you'd
                    like to do this?
                </Typography>
            ),
            controls: {
                secondary: {
                    text: 'Cancel',
                    title: 'Cancel',
                    onClick: closeConfirmation,
                },
                primary: {
                    text: 'Replace items',
                    title: 'Replace items',
                    onClick: loadTheBasket,
                },
            },
        });
    };

    /**
     * Deletes a users basket.
     * @param {string} uuid - Uniques ID of the basket to be deleted.
     */
    const deleteBasket: deleteBasketFunction = async (uuid: any, callback = null) => {
        const runDeleteBasket = async () => {
            closeConfirmation();

            const response = await callDeleteBasket(uuid);

            if (!response) {
                alert('Basket could not be deleted because of an API error. Please try again or contact support.');
                return;
            }

            if (callback) callback();
        };

        openConfirmation({
            heading: 'Delete saved basket?',
            type: 'alert',
            severity: 'error',
            children: (
                <Typography as="p" color="quiet" size="090">
                    Are you sure you want to delete this basket?
                </Typography>
            ),
            controls: {
                secondary: {
                    text: 'Cancel',
                    title: 'Cancel',
                    onClick: closeConfirmation,
                },
                primary: {
                    text: 'Confirm',
                    title: 'Confirm',
                    onClick: runDeleteBasket,
                },
            },
        });
    };

    // TODO: We need to add a use memo to this to prevent re-renders (contextValueRefactor, Ticket: 862jfuy9w).
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
        basket,
        setBasket,
        updateBasket,
        clearBasket,

        loadBasket,
        isLoadingBasket,
        loadBasketError,

        deleteBasket,
        isDeletingBasket,
        deleteBasketError,

        basketIsOpen,
        setBasketIsOpen,

        saveBasket,

        basketUuid,

        loading: updateBasketLoading || saveBasketLoading,
        initialLoadComplete: isLoggedIn ? !isLoadingInitialBasket : true,
    };

    // TODO: Place content of useEffects into their related functions to clean up this file.

    /**
     * On initalBasketData change, set the new basket.
     */
    useEffect(() => {
        if (initialBasketError || initialBasketStatus === 204) {
            clearBasket();
            return;
        }

        if (initialBasketData) {
            if (!basketLastUpdated) setBasketLastUpdated(dayjs.utc().format('YYYY-MM-DD HH:mm:ss'));
            setBasket(initialBasketData.data);
        }
    }, [initialBasketData, initialBasketError, initialBasketStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Ensure user has an up to date basket every 30 secs - calls it immediately when it starts too.
     */
    // @ts-expect-error
    usePollingHook(isLoggedIn && basketUuid ? pollBasket : undefined, 30000, [pollBasket, isLoggedIn], false);
    useLocalStorageSync('last_seen', isLoggedIn ? basketLastUpdated : null);

    return <BasketContext.Provider {...props} value={value} />;
};

/**
 * Hook to be used in components that will allow us access to the values passed into the search provider above.
 */
export const useBasketContext = () => {
    const context = useContext(BasketContext);
    if (context === undefined) {
        throw new Error('useBasketContext must be used within a BasketProvider');
    }
    return context;
};
