import { useEffect } from 'react';
import { TRACKING_ENABLED, isBloomreachEnabled } from '../../../../../config/config';

/**
 * We've had to create this rather than using a script element because for some reason the script tag may run the code twice.
 * The script it loads initiates and loads another script so the tracking code runs twice.
 * Instead of debugging this over and over as its hard to debug locally, we've created this hook to load the script once and only once.
 * @param src
 * @param defer
 */
const useLoadBloomReachScript = (src: string, defer: boolean) => {
    const isBloomreachEnabledInEnv = TRACKING_ENABLED && isBloomreachEnabled();

    useEffect((): undefined | (() => void) | void => {
        if (!isBloomreachEnabledInEnv) return;
        if (typeof window === 'undefined') return;

        // if the script is already in the dom, don't add it again
        if (document.querySelector(`script[src="${src}"]`)) {
            return;
        }

        // dynamically bring in the src script into the head
        const script = document.createElement('script');
        script.src = src;
        script.defer = defer;
        document.head.appendChild(script);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useLoadBloomReachScript;
