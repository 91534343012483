import React, { Fragment, useState } from 'react';
import { navigate } from 'gatsby';

import useSiteMetadata from '../../../../data/staticQueryHooks/useSiteMetaData';
import { useConsultationLauncherContext } from '../../../../data/context/consultationContext/consultationLauncherContext';

import SaveBasketConfirmation from '../SaveBasketConfirmation/SaveBasketConfirmation';
import Typography from '../../../_ui/_blocks/Typography/Typography';
import formatPrice from '../../../../helpers/formatPrice';
import BaseIcon from '../../../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import IconStatusInfo from '../../../_ui/_blocks/Icons/Status/IconStatusInfo';
import Tag from '../../../_ui/_blocks/Tag/Tag';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import Hr from '../../../_ui/_blocks/Hr/Hr';
import Modal from '../../../_ui/Modal/Modal';

import * as styles from './BasketSummary.module.css';

export interface Props {
    basketSubtotal: number;
}

// creating a variable for this to prevent it being hardcoded in a few places
const FREE_SHIPPING_THRESHOLD = 4000;

const BasketSummary = ({ basketSubtotal }: Props) => {
    const [showOverlay, setShowOverlay] = useState(false);

    const { minimumDeliveryCost } = useSiteMetadata();
    const { clearConsultation } = useConsultationLauncherContext();

    let basketTotal: string | number = basketSubtotal;

    // if the basket value is under £40 then we add the minimum delivery cost
    if (basketSubtotal < FREE_SHIPPING_THRESHOLD) {
        basketTotal = basketSubtotal + parseInt(minimumDeliveryCost);
    }

    // if the basket value is over £40 then we just format the basket total and return it
    basketTotal = formatPrice(basketTotal);

    const handleSaveBasketOpen = () => setShowOverlay(true);
    const handleSaveBasketClose = () => setShowOverlay(false);
    const handleCheckoutClick = () => {
        clearConsultation();
        navigate('/checkout');
    };

    return (
        <Fragment>
            <div className={styles.basketSummary}>
                <div className="flex justify-between items-center">
                    <Typography as="h6" typeset="heading" size="090">
                        Subtotal
                    </Typography>
                    <Typography typeset="heading" size="090" color="price">
                        {formatPrice(basketSubtotal)}
                    </Typography>
                </div>
                <Hr />
                <div className="flex items-center justify-between gap-100">
                    <Typography as="h6" typeset="heading" size="090">
                        Delivery estimate
                    </Typography>
                    {basketSubtotal > FREE_SHIPPING_THRESHOLD ? (
                        <Tag size="medium" color="info">
                            Free
                        </Tag>
                    ) : (
                        <Typography as="div" typeset="heading" size="090" color="price">
                            <Typography size="070">from</Typography> {formatPrice(minimumDeliveryCost)}
                        </Typography>
                    )}
                </div>
                {basketSubtotal < FREE_SHIPPING_THRESHOLD ? (
                    <Typography as="div" typeset="subheading" size="080" className={styles.freeDeliveryNotice}>
                        <BaseIcon color="highlight" size="small" className="flex-shrink-0">
                            <IconStatusInfo />
                        </BaseIcon>
                        Free standard delivery on orders over £40
                    </Typography>
                ) : null}
                <Hr />
                <div className="flex items-center justify-between gap-100">
                    <Typography as="h6" typeset="heading" size="090">
                        Order total
                    </Typography>
                    {basketSubtotal < FREE_SHIPPING_THRESHOLD ? (
                        <Typography as="div" typeset="heading" size="090" color="price">
                            <Typography size="070">from</Typography> {basketTotal}
                        </Typography>
                    ) : (
                        <Typography as="div" typeset="heading" size="090" color="price">
                            {basketTotal}
                        </Typography>
                    )}
                </div>
                <Button
                    data-testid="basket-checkout-button"
                    className="w-full text-center"
                    title="Proceed to checkout"
                    onClick={handleCheckoutClick}
                >
                    Checkout
                </Button>
                <div className="text-center">
                    <Button
                        variant="none"
                        size="none"
                        data-testid="basket-save-for-later-button"
                        title="Save you basket for another time"
                        className={styles.saveForLaterButton}
                        onClick={handleSaveBasketOpen}
                    >
                        Save basket for later
                    </Button>
                </div>
            </div>
            <Modal show={showOverlay} onClose={handleSaveBasketClose} className="max-w-md" zIndex="z-1200">
                <SaveBasketConfirmation onClose={handleSaveBasketClose} />
            </Modal>
        </Fragment>
    );
};

export default BasketSummary;
