import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const defaultAttributes = {
    onClosedCallback: undefined,
    heading: undefined,
    icon: undefined,
    fixedToViewport: undefined,
    pin: undefined,
    controls: undefined,
    type: undefined,
    severity: undefined,
    children: undefined,
    className: undefined,
};
/**
 * Start of the confirmation context.
 */
const ConfirmationContext = createContext();

export const ConfirmationProvider = ({ children }) => {
    /**
     * State for if the confirmation is open or not.
     */
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    /**
     * Object containing props for the confirmation component e.g."{heading: '', children: ''}".
     */
    const [confirmationAttributes, setConfirmationAttributes] = useState({});

    /**
     * Functions that open and close the confirmation, done for readibility, bit more obvious than
     * "setIsConfirmationOpen" function name.
     */
    const openConfirmation = (attributes = false) => {
        if (!attributes) return;
        setConfirmationAttributes({ ...defaultAttributes, ...attributes });
        setIsConfirmationOpen(true);
    };

    const closeConfirmation = () => setIsConfirmationOpen(false);

    /**
     * Function passed to the confirmation to be run when the confirmation has closed via CSSTransition
     * component using "onExited".
     *
     * "reset" resets the confirmation states to their defaults in this context.
     *
     * If an "onCloseCompleted" function has been set, it is run before the rest of the clean up
     * functions.
     */
    const reset = useCallback(() => {
        closeConfirmation();
        if (confirmationAttributes.onClosedCallback) confirmationAttributes.onClosedCallback.callback();
        // we have to close the confirmation first then reset the state other wise it will show a flash of the confirmation
        // with no content before it closes.
        setTimeout(() => setConfirmationAttributes({}), 350); // this is the duration of the fade out animation (duration-fast);
    }, [setConfirmationAttributes, confirmationAttributes]);

    // TODO: We need to add a use memo to this to prevent re-renders (contextValueRefactor, Ticket: 862jfuy9w).
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const providerValue = {
        isConfirmationOpen,

        openConfirmation,
        closeConfirmation,

        reset,

        confirmationAttributes,
    };

    return <ConfirmationContext.Provider value={providerValue}>{children}</ConfirmationContext.Provider>;
};

/**
 * Hook to be used in components that will allow us access to the values passed into the
 * "confirmationProvider" above.
 */
export const useConfirmationContext = () => {
    const context = useContext(ConfirmationContext);
    if (context === undefined) {
        throw new Error('useConfirmationContext must be used within a ConfirmationProvider');
    }
    return context;
};

ConfirmationProvider.propTypes = {
    /**
     * Elements to be passed along side confirmation component. Allows for wrapping components in
     * confirmation provider so only one confirmation is required at any time.
     */
    children: PropTypes.node.isRequired,
};

export const withConfirmation = (ComponentToWrap) => (props) =>
    (
        <ConfirmationProvider>
            <ComponentToWrap {...props} />
        </ConfirmationProvider>
    );

export default ConfirmationContext;
