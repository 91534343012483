import React from 'react';

import getTotalTreatmentAndConditionCounts from '../../../data/staticQueryHooks/useTotalTreatmentAndConditionCount';

import Typography from '../../_ui/_blocks/Typography/Typography';
import ButtonLink from '../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';

interface Props {
    closeBasket: () => void;
}

const BasketEmpty = ({ closeBasket }: Props) => {
    const { treatmentCount, conditionCount } = getTotalTreatmentAndConditionCounts();

    return (
        <div className="text-center p-100">
            <Typography as="h2" typeset="title" size="600" color="accent" className="block mt-200">
                You don't have anything in your basket yet
            </Typography>
            <Typography as="p" typeset="subheading" size="080" color="quiet" className="mt-100">
                Find the right treatment for you; We stock {treatmentCount} medicines for {conditionCount} conditions.
            </Typography>
            <ButtonLink
                to="/treatments"
                title="View all treatments"
                className="flex items-center justify-center mt-200"
                onClick={closeBasket}
            >
                View all treatments
            </ButtonLink>
            <ButtonLink
                variant="bordered"
                to="/conditions"
                title="View all conditions"
                className="flex items-center justify-center mt-100"
                onClick={closeBasket}
            >
                View all conditions
            </ButtonLink>
        </div>
    );
};

export default BasketEmpty;
