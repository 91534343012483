import React, { useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { API_ERROR_MESSAGE } from '../../../constants';
import useTreatments from '../../../data/staticQueryHooks/useTreatment';
import dispatchGoogleAnalyticsEvent, { gaEvents, gaFormatters } from '../../../modules/analytics/google/dispatchGoogleAnalyticsEvent';

import Typography from '../../_ui/_blocks/Typography/Typography';
import Card from '../../_ui/_blocks/Card/Card';
import Attention from '../../_ui/_blocks/Attention/Attention';
import IconCross from '../../_ui/_blocks/Icons/Navigation/IconCross';
import ConsultationReorderConfirmSkeleton from './ConsultationReorderConfirmSkeleton';
import TreatmentRefill from '../../Treatment/TreatmentRefill/TreatmentRefill';
import BaseIcon from '../../_ui/_blocks/Icons/BaseIcon/BaseIcon';

const ConsultationReorderConfirm = ({
    loadingTreatment,
    treatmentError,
    getConsultationPanels,
    loadingPanels,
    treatmentToReorder,
    treatment,
    onClose,
}) => {
    const { getConditionSlug } = useTreatments();

    const treatmentId = get(treatment, 'id');
    const conditionSlug = treatmentId ? getConditionSlug(treatmentId) || undefined : undefined;

    // Sends ecom analytics data.
    const analyticsHasRun = useRef(false);
    const handleChange = ({ variant, quantity }) => {
        if (analyticsHasRun.current) {
            return;
        }

        const treatmentData = {
            conditionId: treatment.condition_id,
            treatmentId: variant.id,
            quantity: quantity.quantity,
            selectedVariant: variant,
            selectedQuantityInfo: quantity,
            selectedTreatment: treatment,
        };

        dispatchGoogleAnalyticsEvent(gaEvents.RepeatPurchaseStart, gaFormatters.consultation(treatmentData, true, 'REFILL'));
        analyticsHasRun.current = true;
    };

    // When the reorder treatment form is submitted.
    const handleSubmit = async ({ variant, quantity }) => {
        const treatmentData = {
            conditionId: treatment.condition_id,
            treatmentId: variant.id,
            quantity: quantity.quantity,
            selectedVariant: variant,
            selectedQuantityInfo: quantity,
            selectedTreatment: treatment,
        };

        dispatchGoogleAnalyticsEvent(gaEvents.RepeatPurchaseComplete, gaFormatters.consultation(treatmentData, true, 'REFILL'));

        await getConsultationPanels(treatmentData);
        onClose();
    };

    // If there was an error from the APi getting the treatment details.
    const apiError = useMemo(() => {
        if (!treatmentError) {
            return null;
        }

        const errorIs404 = get(treatmentError, 'response.status') === 404;

        return (
            <Attention type={errorIs404 ? 'info' : 'error'} heading={errorIs404 ? 'Product unavailable' : 'Product details error'}>
                <p className="text-content-quiet">{errorIs404 ? 'This product is currently unavailable.' : API_ERROR_MESSAGE}</p>
            </Attention>
        );
    }, [treatmentError]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        window.addEventListener('routeUpdate', onClose);
        return () => window.removeEventListener('routeUpdate', onClose); // eslint-disable-line consistent-return

        // Removed onClose from the dependency array.
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // If the treatment is loading, return the treatment skeleton.
    if (loadingTreatment) {
        return <ConsultationReorderConfirmSkeleton className="w-full container-xsmall" />;
    }

    return (
        <Card as="div" className="w-full space-y-200 container-xsmall mx-auto my-100 max-h-[calc(100dvh-(1rem*2))] overflow-auto">
            <header>
                <div className="flex justify-between">
                    <Typography as="h3" typeset="heading" size="200">
                        Treatment refill
                    </Typography>
                    <button type="button" onClick={onClose} title="Close treatment refill">
                        <BaseIcon size="small">
                            <IconCross />
                        </BaseIcon>
                    </button>
                </div>
                {!apiError ? (
                    <Typography as="p" color="quiet">
                        {' '}
                        Confirm your treatment before checkout
                    </Typography>
                ) : null}
            </header>
            {apiError || (
                <TreatmentRefill
                    onSubmit={handleSubmit}
                    handleChange={handleChange}
                    treatment={treatment}
                    previousOrder={treatmentToReorder}
                >
                    {({ variant }) => (
                        <TreatmentRefill.AccountRefillButton
                            size="medium"
                            loading={loadingPanels}
                            conditionSlug={conditionSlug}
                            className="w-full text-center"
                            onAlternativesClick={onClose}
                            variant={variant}
                        >
                            Confirm
                        </TreatmentRefill.AccountRefillButton>
                    )}
                </TreatmentRefill>
            )}
        </Card>
    );
};

ConsultationReorderConfirm.defaultProps = {
    treatmentError: null,
    treatment: null,
    treatmentToReorder: null,
};

ConsultationReorderConfirm.propTypes = {
    loadingTreatment: PropTypes.bool.isRequired,
    treatmentError: PropTypes.object,
    getConsultationPanels: PropTypes.func.isRequired,
    loadingPanels: PropTypes.bool.isRequired,
    treatmentToReorder: PropTypes.shape({
        reference: PropTypes.number.isRequired,
        treatment: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
    }),
    treatment: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};

export default ConsultationReorderConfirm;
