import React from 'react';

import Attention from '../../../../_ui/_blocks/Attention/Attention';

/**
 * Notification to the user that the given treatment is not available.
 */
const TreatmentUnavailable = () => (
    <Attention heading="Product unavailable">
        <p>This product is currently unavailable.</p>
    </Attention>
);

export default TreatmentUnavailable;
