import { setCookie } from '../../../../helpers/cookie';

/**
 * When a user clicks on an Awin link, the Awin cookie is stored in the query string.
 * we take the awc value from the query string and store it in a cookie.
 */
export const checkAwinAwcVariable = () => {
    // get awc from query string
    const urlParams = new URLSearchParams(window.location.search);
    const awc = urlParams.get('awc');

    // store it in a cookie
    if (awc) {
        setCookie('awc', awc, 365);
        // remove awc from query string
        window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
    }
};
