import React, { createContext, useState, useContext, useCallback, useEffect, useMemo } from 'react';
import loadable from '@loadable/component';

import useDataApi from '../../../hooks/useDataApi';

import { ConsultationReorderProvider } from './consultationReorderContext';
import masterAnswersLookup from '../../../helpers/masterAnswersLookup';
import consultationService from '../../services/consultation.service';
import { ConsultationPanel } from '../../../types/api/Consultation/ConsultationPanel';
import { Product, ProductVariant } from '../../../types/api/products/Product';
import { ProductsQuantity } from '../../../types/api/products/ProductsQuantity';

// eslint-disable-next-line import/no-cycle
const ConsultationWrapper = loadable(() => import('../../../components/Consultation/ConsultationWrapper'));

export interface PanelsToExclude {
    previouslyOrderedCompleted?: boolean;
    titrationCompleted?: boolean;
}

export interface ConsultationMetaBoltOnsAddedToBasket {
    boltOnsAddedToBasket?: string[]; // Array of their names.
}

/** Data required to launch from a condition */
export interface ConsultationMetaConditionLaunch {
    conditionId: number | string;
    conditionName?: string;
}

/** Data required to launch with a condition */
export interface ConsultationMetaTreatmentLaunch extends ConsultationMetaConditionLaunch, ConsultationMetaBoltOnsAddedToBasket {
    treatmentId: number | string;
    quantity: number;
    selectedQuantityInfo?: ProductsQuantity;
    selectedVariant?: ProductVariant;
    selectedTreatment?: Product;
    introRequired?: boolean;
}

export interface ConsultationMetaRefillLaunch extends ConsultationMetaConditionLaunch, ConsultationMetaBoltOnsAddedToBasket {
    treatmentId: number | string;
    quantity: number;
    introRequired?: boolean;
}

export type ConsultationMeta = ConsultationMetaConditionLaunch | ConsultationMetaTreatmentLaunch;

interface ContextReturnValues {
    getConsultationPanels: (newConsultationMeta: ConsultationMeta) => Promise<any>;
    loadingPanels: boolean;
    consultationMeta: ConsultationMeta;
    consultationPanels: ConsultationPanel[];
    clearConsultation?: any;
}

export interface PanelsToUpdate {
    type: ConsultationPanel['type'];
    action: 'REMOVE' | 'REPLACE' | 'ADD';
    newPanel?: ConsultationPanel;
}

// Start of the consultation treatment context.
const ConsultationLauncherContext = createContext<unknown>({});

/**
 * The provider for the consultation launching the consultation.
 * It also holds the treatment data, the current panels and
 * the current data that we need to submit.
 *
 * The data is looked at with various contexts and various places, however we have to store it in here
 * as the consultation context has a lot of libraries that we only need to load to the user once a consultation is launched.
 * @returns React elements
 */
export const ConsultationLauncherProvider = ({ children }: { children: React.ReactNode }) => {
    const [consultationMeta, setConsultationMeta] = useState<ConsultationMeta | null>(); // the data we need to get the panels and store ids and quantity in
    const [consultationPanels, setConsultationPanels] = useState<null | ConsultationPanel[]>(null);
    const [consultationData, setConsultationData] = useState({}); // user entered data

    /** Gets the list of consultation panels from the API to be used in the consultation component. */
    const requestPanels = useCallback(
        async (newConsultationMeta: ConsultationMeta) => {
            // Force the treatment to get to be the one passed in other wise grab the one we have stored - e.g. when we sign in,
            // we use the current stored treatmentData newTreatmentData doesnt get passed in.
            const formPostData = newConsultationMeta || consultationMeta;

            const response = await consultationService.getConsultationPanels(formPostData);

            if (newConsultationMeta) setConsultationMeta(newConsultationMeta); // TODO: these states could be a reducer, saves calling set state 3 times.
            if (response) setConsultationPanels(response.data.data);
            setConsultationData({});

            /** Our master lookup gets checked every page refresh, we want this to be checked when a consultation starts incase the 60 mins has ran out. */
            masterAnswersLookup.checkDate();

            return response;
        },
        [consultationMeta]
    );

    const [{ isLoading: loadingPanels }, getConsultationPanels] = useDataApi(requestPanels, [requestPanels]);

    /** Clears all consultation data. */
    const clearConsultation = () => {
        setConsultationMeta(null);
        setConsultationPanels(null);
        setConsultationData({});
    };

    /** Keeps existing panel data and adds to it. We use this for setting treatmentId & Quantity on the TreatmentRecommendation Panel */
    const updateConsultationMeta = (newPanelData: ConsultationMeta) => {
        setConsultationMeta((state) => ({ ...state, ...newPanelData }));
    };

    /**
     * Updates the consultation panels via actions to either remove or replace a panel.
     */
    const updateConsultationPanels = (panelsToUpdate: PanelsToUpdate[]) => {
        if (!consultationPanels) {
            return;
        }

        setConsultationPanels((currentPanels) => {
            if (!currentPanels) return null;

            const currentPanelsClone = [...currentPanels];

            panelsToUpdate.forEach((panel) => {
                if (panel.action === 'REMOVE') {
                    const index = currentPanelsClone.findIndex((p) => p.type === panel.type);
                    if (index >= 0) currentPanelsClone.splice(index, 1);
                }

                if (panel.action === 'REPLACE' && panel.newPanel) {
                    const index = currentPanelsClone.findIndex((p) => p.type === panel.type);
                    if (index >= 0) currentPanelsClone.splice(index, 1, panel.newPanel);
                }

                if (panel.action === 'ADD' && panel.newPanel) {
                    currentPanelsClone.push(panel.newPanel);
                }
            });

            return currentPanelsClone;
        });
    };

    // Values passed to the consultation treatment provider.
    // Note: value is now memoized
    const value = useMemo(
        () => ({
            getConsultationPanels,
            loadingPanels,
            consultationMeta,
            consultationPanels,
            clearConsultation,
        }),
        [getConsultationPanels, loadingPanels, consultationMeta, consultationPanels]
    );

    useEffect(() => {
        if (/mobile/i.test(navigator.userAgent) && !window.location.hash) {
            setTimeout(() => {
                window.scrollTo(0, 1);
            }, 1000);
        }
    }, []);

    return (
        <ConsultationLauncherContext.Provider value={value}>
            <ConsultationReorderProvider>
                {consultationMeta ? ( // Do not place below children;
                    <ConsultationWrapper
                        consultationMeta={consultationMeta}
                        updateConsultationMeta={updateConsultationMeta}
                        consultationData={consultationData}
                        setConsultationData={setConsultationData}
                        consultationPanels={consultationPanels}
                        updateConsultationPanels={updateConsultationPanels}
                        clearConsultation={clearConsultation}
                    />
                ) : null}
                {children}
            </ConsultationReorderProvider>
        </ConsultationLauncherContext.Provider>
    );
};

/**
 * Hook to be used in components that will allow us access to the values passed into the search provider
 * above.
 * @param {*} showContextError - sometimes we want to use this but fail silently, such as we need the context on a sign up form, as its required whilst being in the consultation signup
 */
export const useConsultationLauncherContext = (showContextError = true) => {
    const context = useContext<ContextReturnValues>(ConsultationLauncherContext as React.Context<ContextReturnValues>);

    if (context === undefined && showContextError) {
        throw new Error('useConsultationLauncherContext must be used within a ConsultationLauncherProvider');
    }
    return context;
};
