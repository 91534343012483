import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clx from 'classnames';

import ModalTitle from './ModalTitle/ModalTitle';
import ModalDescription from './ModalDescription/ModalDescription';

const colors = {
    soft: 'bg-layers-soft',
    canvas: 'bg-layers-canvas',
};

interface Props {
    children: React.ReactNode;
    show?: boolean;
    onClose?: () => void;
    onClosed?: () => void;
    className?: string;
    color?: keyof typeof colors;
    zIndex?: 'z-800' | 'z-1200';
}

/**
 * A dialog/modal component that can be used to display content on top of the current page.
 * @see https://headlessui.com/react/dialog
 */
const Modal = ({ children, show = false, onClosed, className, color = 'soft', onClose = () => {}, zIndex = 'z-800' }: Props) => {
    const panelClassName = clx('relative', className);

    return (
        <Transition appear show={show} as={Fragment} afterLeave={onClosed}>
            <Dialog as="div" className={`grid place-items-center h-full fixed inset-0 sm:p-100 overflow-auto ${zIndex}`} onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-easeOut duration-fast"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-all ease-easeIn duration-fast"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={`fixed inset-0 opacity-90 z-0 pointer-events-none ${colors[color]}`} />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition-all ease-easeOut duration-fast"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition-all ease-easeIn duration-fast"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className={panelClassName}>{children}</Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
};

Modal.Title = ModalTitle;
Modal.Description = ModalDescription;

export default Modal;
