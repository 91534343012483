import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Typography from '../_ui/_blocks/Typography/Typography';
import FooterPopularConditions from '../Navigation/CraftMenuTypes/FooterPopularConditions/FooterPopularConditions';
import FooterUsefulLinks from '../Navigation/CraftMenuTypes/FooterUsefulLinks/FooterUsefulLinks';
import FooterOurPolicies from '../Navigation/CraftMenuTypes/FooterOurPolicies/FooterOurPolicies';
import CompanyDetails from './CompanyDetails/CompanyDetails';

import * as styles from './Footer.module.css';

const Footer = (): JSX.Element => {
    const data = useStaticQuery(graphql`
        {
            craftcms {
                sitewide: globalSet(handle: "sitewideContent") {
                    ... on CraftCMS_sitewideContent_GlobalSet {
                        companyInformation
                    }
                }
            }
        }
    `);

    const { sitewide } = data.craftcms;

    return (
        <footer className={styles.footer}>
            <div className="container-large container-gutters-medium mx-auto">
                <a href="/" title="Go home" className={styles.logo}>
                    <img
                        src="/images/the-independent-pharmacy-logo.svg"
                        alt="The Independent Pharmacy"
                        loading="lazy"
                        width="124"
                        height="42"
                    />
                </a>
                <section>
                    <div className={styles.container}>
                        <CompanyDetails />

                        <FooterPopularConditions />

                        <FooterUsefulLinks />

                        <FooterOurPolicies />
                    </div>
                </section>
                {sitewide.companyInformation ? (
                    <section className={styles.company_information}>
                        <Typography typeset="heading" size="090" lineHeight="1000">
                            Company Information
                        </Typography>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sitewide.companyInformation,
                            }}
                        />
                    </section>
                ) : null}
            </div>
        </footer>
    );
};

export default Footer;
