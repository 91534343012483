import React, { HTMLAttributes } from 'react';
import clx from 'classnames';

import { fontSizes } from '../Typography/helpers/getFontSize';

import IconStatusInfo from '../Icons/Status/IconStatusInfo';
import IconStatusSuccess from '../Icons/Status/IconStatusSuccess';
import IconStatusWarning from '../Icons/Status/IconStatusWarning';
import BaseIcon from '../Icons/BaseIcon/BaseIcon';
import Typography from '../Typography/Typography';
import IconMedicalCross from '../Icons/IconMedicalCross';

import * as styles from './Attention.module.css';

type TypographyProps = React.ComponentProps<typeof Typography>;
type BaseIconProps = React.ComponentProps<typeof BaseIcon>;

const Icons = {
    info: IconStatusInfo,
    success: IconStatusSuccess,
    warning: IconStatusWarning,
    error: IconStatusWarning,
    medicalCross: IconMedicalCross,
};

interface Props extends HTMLAttributes<HTMLDivElement> {
    type?: 'info' | 'success' | 'warning' | 'error' | 'infoMute';
    icon?: keyof typeof Icons;
    iconSize?: BaseIconProps['size'];
    heading: string | null;
    headingSize?: keyof typeof fontSizes;
    children: TypographyProps['children'];
    className?: string;
}

const typeIcons = {
    info: Icons.info,
    infoMute: Icons.info,
    success: Icons.success,
    warning: Icons.warning,
    error: Icons.warning,
};

const Attention = ({ type = 'info', icon, iconSize, heading, headingSize = '090', children, className, ...rest }: Props) => {
    // Use the icon if provided, otherwise, use the type icon.
    const Icon = icon ? Icons[icon] : typeIcons[type];
    const classNames = clx(styles.attention, styles[type], className);

    return (
        <div {...rest} className={classNames}>
            {heading ? (
                <div className="flex gap-050 items-center">
                    <BaseIcon size={iconSize}>
                        <Icon />
                    </BaseIcon>
                    <Typography as="h4" typeset="heading" size={headingSize}>
                        {heading}
                    </Typography>
                </div>
            ) : null}
            <Typography as="div" typeset="paragraph" size="090">
                {children}
            </Typography>
        </div>
    );
};

export default Attention;
