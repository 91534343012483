import React from 'react';

import { useSelectedQuantityContext, useSelectedVariantContext } from '../../../TreatmentSelectorContext/TreatmentSelectorContext';

import ButtonLink from '../../../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';

type ButtonLinkProps = React.ComponentProps<typeof ButtonLink>;

interface Props extends Omit<ButtonLinkProps, 'children' | 'title' | 'to'> {
    conditionSlug: string;
}

const ContinueToAssessmentButton = ({ conditionSlug, ...rest }: Props) => {
    const { name, id } = useSelectedVariantContext();
    const selectedQuantity = useSelectedQuantityContext();

    const quantity = selectedQuantity?.quantity || 0;

    const to = `/${conditionSlug}/request-consultation?variant=${id}&quantity=${quantity}`;

    return (
        <ButtonLink {...rest} to={to} title={`Complete an assessment to add ${name} to your basket`}>
            Continue to Assessment
        </ButtonLink>
    );
};

export default ContinueToAssessmentButton;
