import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import Header from '@components/Navigation/Header/Header';
import Basket from '@components/Basket/Basket';
import SidebarNavigation from '@components/Navigation/CraftMenuTypes/SidebarNavigation/SidebarNavigation';
import loadable from '@loadable/component';
import { AccountNavigationProvider } from '@data/context/accountContext/accountNavigationContext';
import Confirmation from '@components/_ui/Confirmation/Confirmation';
import AccountMenuWrapper from '@components/Navigation/AccountNavigation/AccountMenuWrapper/AccountMenuWrapper';
import DrawerNavigation from '../../components/DrawerNavigation/DrawerNavigation';
import SessionActivity from '../../components/Authentication/SessionActivity/SessionActivity';
import BasketBubble from '../../components/Basket/BasketBubble/BasketBubble';
import AccountMenu from '../../components/Navigation/AccountNavigation/AccountMenu/AccountMenu';
import * as styles from './AccountLayout.module.css';

const SearchOverlay = loadable(() => import('@components/Search/SearchOverlay/SearchOverlay'));
const CookiesNotification = loadable(() => import('@components/CookiesNotification/CookiesNotification'));

/**
 * Account Layout
 * Global providers sit in the account.jsx page for this. We've had to lock this behind auth.
 * So our routing means these are private and need to be stored at a higher spot when this is rendered at route level
 */
const AccountLayout = ({ children }) => (
    <Fragment>
        <Helmet htmlAttributes={{
            class: styles.accountLayout
        }}>
            <title>Account</title>
        </Helmet>
        <AccountNavigationProvider>
            <div className='min-h-full flex flex-col'>
                <Header disableMainNav />
                <div className="mx-auto max-w-[135rem] flex min-h-full w-full flex-grow">
                    <AccountMenuWrapper>
                        <AccountMenu />
                    </AccountMenuWrapper>
                    <main className="flex-grow pb-400 md:pb-200">{children}</main>
                </div>
            </div>
            <Basket />
            <BasketBubble />
            <DrawerNavigation />
            <SidebarNavigation />
            <SearchOverlay />
            <Confirmation />
            <CookiesNotification />
        </AccountNavigationProvider>
        <SessionActivity />
    </Fragment>
);

AccountLayout.propTypes = {
    /** The page content components */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default AccountLayout;
