import get from 'lodash.get';
import { checkBrEnv } from './helpers/checkBrEnv';
import { processQueue } from './helpers/brEventQueue';
import {
    BloomreachCategoryPageView,
    BloomreachConversionPageView,
    BloomreachHomepagePageView,
    BloomreachOtherPageView,
    BloomreachProductPageView,
    BloomreachSearchPageView,
} from './types/bloomreachPixelTypes';
import { defaultBRData } from './config/brdata.config';
import auth from '../../../auth/auth';
import { brScriptSrc } from './config/brscript.config';
import { brFormatOrderToConversion } from './formatters/brFormatOrderToConversion';

const bloomreachLoaded = () => document.querySelector(`script[src="${brScriptSrc}"]`);

const loadBloomreachScript = () => {
    const brtrk = document.createElement('script');
    brtrk.type = 'text/javascript';
    brtrk.async = true;
    brtrk.defer = true;
    brtrk.src = brScriptSrc;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode?.insertBefore(brtrk, s);
    brtrk.onload = () => {
        // Wait for brtrk script to load in and initiate first view.
        setTimeout(() => processQueue(), 300);
    };
};

export const brPageViewFormatters = {
    conversion: brFormatOrderToConversion,
};

/**
 * We want to simply send the tracking data to bloom reach, we either do that by setting
 * br_data in the window and loading the script in, or we update the data and send the page view
 * through the BrTrk object.
 * @param brData
 * @returns
 */
const sendBloomreachPageViewTracking = (
    brData:
        | BloomreachOtherPageView
        | BloomreachHomepagePageView
        | BloomreachProductPageView
        | BloomreachCategoryPageView
        | BloomreachSearchPageView
        | BloomreachConversionPageView
) => {
    const isBloomreachEnabledInEnv = checkBrEnv();
    if (!isBloomreachEnabledInEnv) return;
    if (typeof window === 'undefined') return;

    // if its loaded then we need to send the page view.
    if (bloomreachLoaded()) {
        if (!window.BrTrk) return;

        window.BrTrk.getTracker().updateBrData({
            ...(window.initial_br_data || {}),
            title: document.title,
            orig_ref_url: window.location.href,
            ...brData,
        });
        window.BrTrk.getTracker().logPageView();

        return;
    }

    // send page view and load script in
    window.br_data = {
        ...defaultBRData,
        title: document.title,
        orig_ref_url: window.location.href,
        user_id: get(auth.getLoginInfo(), 'patient_uuid', ''),
        ...brData,
    };
    window.initial_br_data = { ...window.br_data };

    loadBloomreachScript();
};

export const bloomreachPixelSendOtherView = () => sendBloomreachPageViewTracking({ ptype: 'other' });
export const bloomreachPixelSendHomepageView = () => sendBloomreachPageViewTracking({ ptype: 'homepage' });
export const bloomreachPixelSendProductView = (brData: Omit<BloomreachProductPageView, 'ptype'>) =>
    sendBloomreachPageViewTracking({ ...brData, ptype: 'product' });
export const bloomreachPixelSendCategoryView = (brData: Omit<BloomreachCategoryPageView, 'ptype'>) =>
    sendBloomreachPageViewTracking({ ...brData, ptype: 'category' });
export const bloomreachPixelSendSearchView = (brData: Omit<BloomreachSearchPageView, 'ptype'>) =>
    brData.search_term && sendBloomreachPageViewTracking({ ...brData, ptype: 'search', catalogs: [{ name: 'theindependentpharmacy' }] });
export const bloomreachPixelSendConversionView = (brData: Omit<BloomreachConversionPageView, 'ptype'>) =>
    sendBloomreachPageViewTracking({ ...brData, ptype: 'conversion' });
