import React from 'react';
import clx from 'classnames';

import IconCross from '../../_ui/_blocks/Icons/Navigation/IconCross';
import BaseIcon from '../../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import IconStatusWarning from '../../_ui/_blocks/Icons/Status/IconStatusWarning';
import IconStatusInfo from '../../_ui/_blocks/Icons/Status/IconStatusInfo';
import IconStatusSuccess from '../../_ui/_blocks/Icons/Status/IconStatusSuccess';
import Typography from '../../_ui/_blocks/Typography/Typography';

import * as styles from './Flash.module.css';

export enum Types {
    info = 'info',
    success = 'success',
    warning = 'warning',
    error = 'error',
}

interface Props {
    type: keyof typeof Types;
    message: string;
    onClose?: () => void;
    important?: boolean;
}

const Flash = ({ type, message, onClose = undefined, important }: Props) => {
    const classNames = clx(styles.flash, styles[Types[type]]);

    return (
        <div className={classNames}>
            <BaseIcon size="small">
                {type === 'warning' || type === 'error' ? <IconStatusWarning /> : null}
                {type === 'warning' || type === 'error' ? <IconStatusWarning /> : null}
                {type === 'info' ? <IconStatusInfo /> : null}
                {type === 'success' ? <IconStatusSuccess /> : null}
            </BaseIcon>
            <Typography as="p" typeset={important ? 'heading' : undefined} size="090">
                {message}
            </Typography>
            {onClose ? (
                <button type="button" className="button--transparent" onClick={onClose} title="Close message notification">
                    <BaseIcon size="small">
                        <IconCross />
                    </BaseIcon>
                </button>
            ) : null}
        </div>
    );
};

export default Flash;
