import React from 'react';

const IconChevronDown = () => (
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6339 16.3605C39.122 16.8411 39.122 17.6204 38.6339 18.1011L24.8839 31.6395C24.6495 31.8703 24.3315 32 24 32C23.6685 32 23.3505 31.8703 23.1161 31.6395L9.36612 18.1011C8.87796 17.6204 8.87796 16.8411 9.36612 16.3605C9.85427 15.8798 10.6457 15.8798 11.1339 16.3605L24 29.0287L36.8661 16.3605C37.3543 15.8798 38.1457 15.8798 38.6339 16.3605Z"
    />
);

export default IconChevronDown;
