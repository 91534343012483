import React from 'react';

import { useSelectedVariantContext } from '../../TreatmentSelectorContext/TreatmentSelectorContext';
import useFormatQuantitiesForDisplay from '../../hooks/useFormatQuantitiesForDisplay/useFormatQuantitiesForDisplay';
import useFilterQuantitiesByFeatured from './hooks/useFilterQuantitiesByFeatured';

import QuantityPricing from '../../QuantityPricing/QuantityPricing';

import * as styles from './PriceComparison.module.css';

/**
 * Used to display a list of quantities and their prices that are not selectable for ODONLY treatments.
 */
const PriceComparison = () => {
    const selectedVariant = useSelectedVariantContext();
    const { quantities } = useFormatQuantitiesForDisplay(selectedVariant);
    const featuredQuantities = useFilterQuantitiesByFeatured(quantities);

    return (
        <div className="space-y-100" data-testid="comparison-quantity">
            <ul className={styles.listing}>
                {featuredQuantities.map((quantity) => {
                    const key = `${quantity.quantity}-${quantity.label}`;

                    return (
                        <QuantityPricing
                            as="li"
                            key={key}
                            unitCost={quantity.unit_cost}
                            price={quantity.price}
                            saving={quantity.saving}
                            label={quantity.label}
                            pricePer={quantity.price_per}
                        />
                    );
                })}
            </ul>
        </div>
    );
};

export default PriceComparison;
