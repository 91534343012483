/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import useManageBasketItems from '../../../data/context/basketContext/hooks/useManageBasketItems';
import basketItemShape from '../propTypes/basketItemShape';
import { API_ERROR_MESSAGE, QUANTITY_TYPES } from '../../../constants';
import { BasketItem } from '../../../types/api/basket/BasketItem';
import { ProductsQuantity } from '../../../types/api/products/ProductsQuantity';

import Overlay from '../../_ui/_blocks/Overlay/Overlay';
import ButtonBusy from '../../_ui/_blocks/Buttons/ButtonBusy/ButtonBusy';
import Attention from '../../_ui/_blocks/Attention/Attention';
import TimedSelector from './TimedSelector';
import RadioSelector from './RadioSelector';
import Card from '../../_ui/_blocks/Card/Card';
import Button from '../../_ui/_blocks/Buttons/Button/Button';
import Typography from '../../_ui/_blocks/Typography/Typography';

interface Props {
    itemToUpdate: BasketItem;
    onClose: (args?: any) => void;
    onSuccess?: (args?: any) => void;
    fixedToViewport?: boolean;
}

const BasketQuantitySelector = ({ itemToUpdate, onClose, onSuccess, fixedToViewport = false }: Props) => {
    const [isOpen, setIsOpen] = useState(!!itemToUpdate);
    const [item, setItem] = useState<BasketItem | null>(null);
    const [newQuantity, setNewQuantity] = useState<ProductsQuantity | null>(null);

    // Updates the new quantity state.
    const handleQuantityChange = (quantity: ProductsQuantity | null) => {
        setNewQuantity(quantity);
    };

    const QuantitySelector = useMemo(() => {
        if (!item) return null;
        return item.quantity_type.toUpperCase() === QUANTITY_TYPES.timed ? TimedSelector : RadioSelector;
    }, [item]);

    // Helpful basket update functions.
    const { updateItems, isLoading, error } = useManageBasketItems();

    // Sends API patch request to update the basket with the new quantity.
    const updateQuantity = () => {
        if (newQuantity === null) {
            return null;
        }

        const { quantity, price: total, label, unit_cost, customer_label } = newQuantity;

        const basketItemClone = {
            ...item,
            quantity,
            total,
            unit_cost,
            label,
            customer_label,
            has_been_updated: true,
        };

        if (basketItemClone.consultation) {
            basketItemClone.consultation = { ...basketItemClone.consultation, quantity };
        }

        // @ts-expect-error
        return updateItems([basketItemClone]);
    };

    /**
     * Used to disable the form submit button.
     */
    const disabled = !newQuantity || !newQuantity.quantity || item?.quantity === newQuantity.quantity;

    /**
     * Runs after submission form has closed, cleans up states.
     */
    const handleHide = () => {
        setItem(null);
        setNewQuantity(null);
    };

    /**
     * On submit of update quantity form.
     */
    const handleSubmit = async (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();

        if (!newQuantity || !item) return;

        if (!(await updateQuantity())) {
            return;
        }

        if (onSuccess) onSuccess(newQuantity);

        onClose();
    };

    /**
     * On change of basket item to update.
     */
    useEffect(() => {
        if (itemToUpdate) {
            setIsOpen(true);
            setItem(itemToUpdate);
            // @ts-expect-error
            setNewQuantity({ quantity: itemToUpdate.quantity, total: itemToUpdate.total });
            return;
        }

        setIsOpen(false);
    }, [itemToUpdate]);

    return (
        <Overlay className="flex items-center p-100 !m-0" show={isOpen} onHide={handleHide} fixedToViewport={fixedToViewport}>
            <Card as="form" className="w-full space-y-100 max-w-[21.5rem] mx-auto" onSubmit={handleSubmit}>
                {item ? (
                    <Fragment>
                        <Typography as="label" typeset="heading" size="090" htmlFor="quantity-change-selector">
                            Update quantity
                        </Typography>
                        {item.quantityChangeQuestion ? (
                            <Typography as="p" typeset="subheading" size="080" color="error500">
                                Changing this quantity will need us to ask you some more questions before checkout.
                            </Typography>
                        ) : null}
                        {QuantitySelector ? (
                            <div className="max-h-[45dvh] overflow-auto">
                                <QuantitySelector
                                    id="quantity-change-selector"
                                    item={item}
                                    onChange={handleQuantityChange}
                                    selectedQuantity={newQuantity?.quantity}
                                />
                            </div>
                        ) : null}
                    </Fragment>
                ) : null}
                {error ? (
                    <Attention type="error" heading="Update error" headingSize="090">
                        <Typography as="p" size="090">
                            {API_ERROR_MESSAGE}
                        </Typography>
                    </Attention>
                ) : null}
                <div className="flex gap-100 justify-end">
                    <Button variant="bordered" className="flex-shrink-0" title="Cancel quantity change" onClick={onClose}>
                        Cancel
                    </Button>
                    <ButtonBusy
                        type="submit"
                        className="flex-grow"
                        loading={isLoading}
                        disabled={disabled}
                        title="Update quantity"
                        size="small"
                    >
                        Update quantity
                    </ButtonBusy>
                </div>
            </Card>
        </Overlay>
    );
};

BasketQuantitySelector.defaultProps = {
    itemToUpdate: null,
    onSuccess: null,
};

BasketQuantitySelector.propTypes = {
    itemToUpdate: basketItemShape,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
};

export default BasketQuantitySelector;
