import get from 'lodash.get';

import { getAffiliateJwtDecoded } from '../../../../affiliate/helpers/getAffiliateJwt';
import formatProducts, { FormatProductsReturnType } from './helpers/formatProducts';
import formatFreshDataProducts, { FormatFreshDataProductsReturnType } from './helpers/formatFreshDataProducts';
import Order from '../../../../../types/api/order/Order';

type GetTreatmentImageType = (reference: number) => string;
type GetTreatmentDescriptionType = (reference: number) => string;

interface ReturnType {
    ecommerce: {
        purchase: {
            paymentMethod: string;
            deliveryType: string;
            actionField: {
                id: string;
                affiliation: string;
                revenue: string;
                tax: string;
                shipping: string;
                coupon: string;
                netRev: string;
            };
            products: FormatProductsReturnType[];
        };
    };
    freshDataLayer: {
        products: FormatFreshDataProductsReturnType[];
    };
}

/**
 * Formats the analytics data for order completion.
 * @param {object} order - The created order.
 * @param {string} paymentMethod - The payment method used to place the order.
 * @param {function} getTreatmentImage - Gets a treatments image.
 * @param {function} getTreatmentDescription - Gets a treatments description.
 * @returns {object}
 */
const formatOrderCompleteAnalytics = (
    order: Order,
    paymentMethod: string,
    getTreatmentImage: GetTreatmentImageType,
    getTreatmentDescription: GetTreatmentDescriptionType
): ReturnType => {
    const { uuid, total, items, vat } = order; // eslint-disable-line object-curly-newline

    const decodedJwt = getAffiliateJwtDecoded({});

    const affiliateCode = get(decodedJwt, 'affiliate_code') || null;

    const deliveryPrice = get(order, 'delivery.method.price') || 0;
    const discountCode = get(order, 'discount.code') || '';

    const orderTotal = ((total || 0) / 100).toFixed(2);
    const vatTotal = ((vat || 0) / 100).toFixed(2);
    const deliveryCost = (deliveryPrice / 100).toFixed(2);

    return {
        ecommerce: {
            purchase: {
                paymentMethod,
                deliveryType: get(order, 'delivery.method.label') || '',
                actionField: {
                    id: uuid,
                    affiliation: get(decodedJwt, 'affiliate_name') || 'The Independent Pharmacy',
                    revenue: orderTotal, // Total revenue including tax & shipping.
                    tax: vatTotal,
                    shipping: deliveryCost,
                    coupon: discountCode,
                    netRev: ((total - vat - deliveryPrice) / 100).toFixed(2),
                },
                products: formatProducts(items, affiliateCode),
            },
        },
        freshDataLayer: {
            products: formatFreshDataProducts(items, getTreatmentImage, getTreatmentDescription),
        },
    };
};

export default formatOrderCompleteAnalytics;
