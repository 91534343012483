import React from 'react';

import formatPrice from '../../../../helpers/formatPrice';
import { ProductsQuantity } from '../../../../types/api/products/ProductsQuantity';

import Typography from '../../../_ui/_blocks/Typography/Typography';

interface Props {
    unitCost: ProductsQuantity['unit_cost'];
    price: ProductsQuantity['price'];
    saving?: ProductsQuantity['saving'];
    label?: string;
    as?: 'div' | 'li';
    pricePer?: ProductsQuantity['price_per'];
}

/**
 * Pricing of a quantity to be displayed.
 */
const QuantityPricing = ({ as = 'div', unitCost, label, price, saving, pricePer }: Props) => {
    const Tag = as;

    return (
        <Tag className="space-y-0125">
            <div className="flex gap-100 justify-between">
                <Typography as="div" typeset="heading" size="090" lineHeight="100">
                    {label}
                </Typography>
                <Typography as="div" typeset="heading" color="accent" size="090" lineHeight="none">
                    {formatPrice(price)}
                </Typography>
            </div>
            <div className="flex gap-100 justify-between items-center">
                <Typography as="div" size="080" color="quiet">
                    {pricePer || `${formatPrice(unitCost)} per pack`}
                </Typography>
                {saving ? (
                    <Typography as="div" size="080" color="saving" lineHeight="none">
                        Save {formatPrice(saving)}
                    </Typography>
                ) : null}
            </div>
        </Tag>
    );
};

export default QuantityPricing;
