import React from 'react';

import { useRefillBlockerContext } from '../../hooks/useRefillBlockers/RefillBlockerContext';
import { ProductVariant } from '../../../../../types/api/products/Product';

import OpenBasketButton from '../../../TreatmentSelector/SelectorButtons/Buttons/OpenBasketButton/OpenBasketButton';
import ButtonBusy from '../../../../_ui/_blocks/Buttons/ButtonBusy/ButtonBusy';
import ButtonLink from '../../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';

type ButtonBusyProps = React.ComponentProps<typeof ButtonBusy>;

interface Props extends Pick<ButtonBusyProps, 'className' | 'size' | 'loading' | 'children'> {
    conditionSlug?: string;
    onAlternativesClick?: () => void;
    variant?: ProductVariant;
}

/**
 * Decides what submission button to show during the refill process for the treatment selector.
 */
const AccountRefillButton = ({ className, loading, children, variant, size, onAlternativesClick, conditionSlug }: Props) => {
    const refillBlockers = useRefillBlockerContext();

    const blockerTypes = refillBlockers.map(({ type }) => type);

    const props = {
        className,
        size,
        loading,
    };

    if (blockerTypes.includes('ALREADY_IN_BASKET') || blockerTypes.includes('BLOCKED_BY_BASKET')) {
        return <OpenBasketButton {...props} />;
    }

    if (conditionSlug) {
        if (blockerTypes.includes('GENDER_RESTRICTED')) {
            return (
                <ButtonLink {...props} to={`/${conditionSlug}`} onClick={onAlternativesClick} title="Find an alternative treatment">
                    Find alternatives
                </ButtonLink>
            );
        }

        if (blockerTypes.includes('OUT_OF_STOCK')) {
            const buttonText = variant?.out_of_stock_button_text || 'Find alternatives';
            return (
                <ButtonLink
                    {...props}
                    to={variant?.out_of_stock_button_url || `/${conditionSlug}`}
                    onClick={onAlternativesClick}
                    title={buttonText}
                >
                    {buttonText}
                </ButtonLink>
            );
        }
    }

    if (blockerTypes.length) {
        return null;
    }

    return (
        <ButtonBusy {...props} type="submit" title="Refill treatment">
            {children}
        </ButtonBusy>
    );
};

export default AccountRefillButton;
