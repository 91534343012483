/* eslint-disable indent */
import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import IconBasket from '@components/_ui/_blocks/Icons/IconBasket';
import IconCheckmark from '@components/_ui/_blocks/Icons/Navigation/IconCheckmark';
import BaseIcon from '../../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import { isBrowser } from '../../../config/config';
import { useBasketContext } from '../../../data/context/basketContext/basketContext';

import NumberIncrement from '../../_ui/_blocks/NumberIncrement/NumberIncrement';

import * as styles from './BasketBubble.module.css';

const BasketBubble = () => {
    const [displayState, setDisplayState] = useState('hidden');
    // @ts-expect-error
    const { basket, initialLoadComplete, setBasketIsOpen } = useBasketContext();

    const clearSavedDisplayState = () => setDisplayState('hidden');
    const setSavedAsDisplayState = () => setDisplayState('saved');

    const numberOfBasketItems = useMemo(() => (basket && basket.items ? basket.items.length : 0), [basket]);

    useLayoutEffect(() => {
        if (displayState === 'saved') return;

        if (!numberOfBasketItems) {
            setDisplayState('hidden');
            return;
        }

        setDisplayState('visible');
    }, [displayState, numberOfBasketItems]);

    // @ts-expect-error
    useEffect(() => {
        if (!isBrowser()) return null;

        window.addEventListener('basket_saved', setSavedAsDisplayState);

        return () => window.removeEventListener('basket_saved', setSavedAsDisplayState);
    }, []);

    if (!initialLoadComplete) return null;

    switch (displayState) {
        case 'visible':
            return (
                <button
                    data-testid="das"
                    className={`${styles.mobileBubblesBasket} ${styles.mobileBubblesBasketShow}`}
                    type="button"
                    onClick={() => setBasketIsOpen(true)}
                >
                    <BaseIcon>
                        <IconBasket />
                    </BaseIcon>
                    <div className={`${styles.mobileBubblesCount} ${styles.mobileBubblesCountShow}`}>
                        <NumberIncrement number={numberOfBasketItems} />
                    </div>
                </button>
            );
        case 'saved':
            return (
                <CSSTransition timeout={{ enter: 0, exit: 2900 }} in appear mountOnEnter unmountOnExit onExited={clearSavedDisplayState}>
                    <button data-testid="das" className={`${styles.mobileBubblesBasket} ${styles.aMobileBubblesBasketSave}`} type="button">
                        <div className={styles.aMobileBubblesSaveOverlay}>
                            <BaseIcon>
                                <IconCheckmark />
                            </BaseIcon>
                        </div>
                        <BaseIcon>
                            <IconBasket />
                        </BaseIcon>
                        <div className={`${styles.mobileBubblesCount} ${styles.aMobileBubblesBasketSave}`}>
                            <NumberIncrement number={0} />
                        </div>
                    </button>
                </CSSTransition>
            );
        case 'hidden':
            return null;
        default:
            return null;
    }
};

export default BasketBubble;
