// extracted by mini-css-extract-plugin
export var avatar = "avatar_XbgD6MECdZ";
export var borderForeground = "borderForeground_ZRocrfms6E";
export var full = "full_nSU8CownX+";
export var halfPillDown = "halfPillDown_NcNHZJgENS";
export var halfPillUp = "halfPillUp_1kIAuVohyl";
export var large = "large_ik5eOJdQKa";
export var medium = "medium_75Eo6WK-tM";
export var pill = "pill_T0MnmKDPP+";
export var small = "small_1NhF1CR0r+";
export var xlarge = "xlarge_kW8G-MCoaW";