import React, { Fragment } from 'react';

import useManageBasketItems from '../../../../../../data/context/basketContext/hooks/useManageBasketItems';
import useTreatmentImage from '../../../../../../data/staticQueryHooks/useTreatmentImage';
import { DeleteCardOverlay, DeleteCardOverlayTrigger } from '../../../../../_ui/Card/DeleteCardOverlay/DeleteCardOverlay';
import { BasketItem } from '../../../../../../types/api/basket/BasketItem';

import QuantityButton from '../QuantityButton/QuantityButton';
import Typography from '../../../../../_ui/_blocks/Typography/Typography';
import Tag from '../../../../../_ui/_blocks/Tag/Tag';

import * as styles from '../../BasketItem.module.css';

export interface Props {
    item: BasketItem;
    openQuantitySelector: (args: any) => void;
}

const QuantityUnavailableBasketItem = ({ item, openQuantitySelector }: Props) => {
    const { getTreatmentImage } = useTreatmentImage();
    const { deleteItems } = useManageBasketItems();

    const treatmentImage = getTreatmentImage(item.reference) || '/images/pill.svg';

    return (
        <DeleteCardOverlay
            className={styles.item}
            title={item.treatment}
            description="Are you sure? This will also delete the consultation for this treatment."
            onDelete={() => deleteItems([{ id: item.id }])}
        >
            <Fragment>
                <div data-testid="quantity_outofstock_basket_item" className="flex items-start gap-100">
                    <img width={52} height={52} className={styles.image} src={treatmentImage} alt={item.treatment} />
                    <div className="flex-grow space-y-050">
                        <Tag size="medium" color="error">
                            Quantity unavailable
                        </Tag>
                        <Typography as="h6" typeset="heading" size="080" lineThrough>
                            {item.treatment}
                        </Typography>
                        <QuantityButton onClick={openQuantitySelector} item={item} />
                    </div>
                </div>
                <DeleteCardOverlayTrigger title={`Remove ${item.treatment} from your basket`} />
            </Fragment>
        </DeleteCardOverlay>
    );
};

export default QuantityUnavailableBasketItem;
