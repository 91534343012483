/* eslint-disable @typescript-eslint/no-var-requires */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require('./src/vendor/visibility_api_polyfill/visibility-api-polyfill');
// require('./src/scss/styles.scss');
require('./src/config/axios/axios_config');
require('./src/config/dayjs_config');
require('./src/styles/globals.css');

const { checkAffiliateCode } = require('./src/modules/affiliate/manageAffiliateCode');
const { checkAwinAwcVariable } = require('./src/components/Tracking/Awin/helper/checkAwinAwcVariable');

const layoutWrap = require('./src/layout/layoutWrap').default;
const providerWrap = require('./src/layout/providerWrap').default;

const { isBrowser } = require('./src/config/config');

const checkMaintenanceMode = () => {
    if (process.env.GATSBY_SITE_MAINTENANCE_MODE === 'true' && window.location.pathname !== '/maintenance') {
        window.location = '/maintenance';
    } else if (process.env.GATSBY_SITE_MAINTENANCE_MODE !== 'true' && window.location.pathname === '/maintenance') {
        window.location = '/';
    }
};

exports.disableCorePrefetching = () => true;

exports.onInitialClientRender = async () => {
    await checkAffiliateCode();
    await checkAwinAwcVariable();
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
    checkMaintenanceMode();

    if (isBrowser()) {
        window.dispatchEvent(
            new CustomEvent('routeUpdate', {
                detail: {
                    location,
                    prevLocation,
                },
            })
        );
    }
};

exports.onClientEntry = () => {
    checkMaintenanceMode();
};

exports.wrapPageElement = layoutWrap;
exports.wrapRootElement = providerWrap;
