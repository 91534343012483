import React from 'react';

import * as styles from './LoadingSkeleton.module.css';

/**
 * Loading skeleton shown whilst treatment selector is loading.
 */
const LoadingSkeleton = () => {
    const optionClassName = `skeleton-animation ${styles.option}`;

    return (
        <div className="space-y-150">
            <div className="space-y-050">
                <div className={optionClassName} />
                <div className={optionClassName} />
                <div className={optionClassName} />
            </div>
            <div className="space-y-050">
                <div className={optionClassName} />
                <div className={optionClassName} />
                <div className={optionClassName} />
            </div>
            <div className={`skeleton-animation ${styles.button}`} />
        </div>
    );
};

export default LoadingSkeleton;
