import React, { Fragment, PropsWithChildren } from 'react';
import loadable from '@loadable/component';

import Header from '../../components/Navigation/Header/Header';
import SessionActivity from '../../components/Authentication/SessionActivity/SessionActivity';
import AffiliateBanner from '../../modules/affiliate/components/AffiliateBanner';

const CookiesNotification = loadable(() => import('@components/CookiesNotification/CookiesNotification'));

/**
 * Minimal layout component for pages like maintenance.
 * @param {object} props
 * @returns {ReactElement}
 */
const MaintenanceLayout = ({ children }: PropsWithChildren) => (
    <Fragment>
        <AffiliateBanner />
        <Header disableSearch disableAccount disableBasket disableBurgerMenu disableMainNav />
        <main>{children}</main>
        <CookiesNotification />
        <SessionActivity />
    </Fragment>
);

export default MaintenanceLayout;
