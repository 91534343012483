import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import Basket from '../../components/Basket/Basket';
import SidebarNavigation from '../../components/Navigation/CraftMenuTypes/SidebarNavigation/SidebarNavigation';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Navigation/Header/Header';
import Confirmation from '../../components/_ui/Confirmation/Confirmation';
import DrawerNavigation from '../../components/DrawerNavigation/DrawerNavigation';
import AssessmentWrapper from '../../components/Assessment/AssessmentWrapper';
import SessionActivity from '../../components/Authentication/SessionActivity/SessionActivity';
import AffiliateBanner from '../../modules/affiliate/components/AffiliateBanner';

const BasketBubble = loadable(() => import('@components/Basket/BasketBubble/BasketBubble'));
const CookiesNotification = loadable(() => import('@components/CookiesNotification/CookiesNotification'));

/**
 * Layout component for affiliate pages.
 * @param {object} props
 * @returns {ReactElement}
 */
const AffiliateLayout = ({ children }) => (
    <Fragment>
        <AffiliateBanner />
        <Header disableSearch disableBurgerMenu disableMainNav />
        <main>{children}</main>
        <Footer />
        <Basket />
        <BasketBubble />
        <DrawerNavigation />
        <SidebarNavigation />
        <CookiesNotification />
        <AssessmentWrapper />
        <Confirmation />
        <SessionActivity />
    </Fragment>
);

AffiliateLayout.propTypes = {
    /**
     * The page content components.
     */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default AffiliateLayout;
