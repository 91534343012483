import React from 'react';
import { Helmet } from 'react-helmet-async';

import { TRACKING_ENABLED, getDeploymentEnvironment, isBloomreachEnabled } from '../../../../config/config';
import useLoadBloomReachScript from './hooks/useLoadBloomReachScript';
import useStartExponea from './hooks/useStartExponea';

export const getBloomreachSdkSrc = () =>
    getDeploymentEnvironment() === 'production'
        ? '/third-party/bloomreach/sdk/production.js'
        : '/third-party/bloomreach/sdk/development.js';
export const getPrefetchURl = () =>
    getDeploymentEnvironment() === 'production'
        ? '//analytics.theindependentpharmacy.co.uk'
        : '//analytics.staging.theindependentpharmacy.co.uk';

interface Props {
    defer?: boolean;
}

/**
 * Returns the Bloomreach script tag with the correct SRC based on the environment. Script code is found in
 * our static folder.
 * NOTE: This component is used inside the gatsby-ssr.jsx file in the root of the project to add this script
 * tag to every page.
 */
const BloomreachScriptTag = ({ defer = true }: Props) => {
    const src = getBloomreachSdkSrc();
    const isBloomreachEnabledInEnv = TRACKING_ENABLED && isBloomreachEnabled();

    useLoadBloomReachScript(src, defer);
    useStartExponea();

    if (!isBloomreachEnabledInEnv) return null;

    return (
        <Helmet>
            <link rel="preconnect" href={getPrefetchURl()} />
        </Helmet>
    );
};

export default BloomreachScriptTag;
