import React from 'react';

import { ProductVariant } from '../../../../../types/api/products/Product';

import Attention from '../../../../_ui/_blocks/Attention/Attention';

interface Props {
    conditionName?: string;
    variant: ProductVariant | null;
    isRefill?: boolean;
}

/**
 * Returns a default message when a treatment is out of stock.
 */
export const getDefaultStockMessage = (isRefill: Props['isRefill'], conditionName?: Props['conditionName']) => {
    let baseMessage = `
        Sorry, this item is currently out of stock and we do not have a date for when it will be available
        from the manufacturer.
    `;

    if (isRefill) {
        return baseMessage;
    }

    baseMessage += ' You can view our alternate treatments';
    return !conditionName || conditionName === 'General Health' ? `${baseMessage} below.` : `${baseMessage} for ${conditionName} below.`;
};

/**
 * Notification to the user that the given treatment is out of stock.
 */
const OutOfStockNotification = ({ conditionName, variant, isRefill = false }: Props) => {
    const outOfStockMessage = variant?.out_of_stock_message;

    return (
        <Attention heading="This treatment is currently out of stock" type="warning">
            {outOfStockMessage ? (
                <p dangerouslySetInnerHTML={{ __html: outOfStockMessage }} />
            ) : (
                <p>{getDefaultStockMessage(isRefill, conditionName)}</p>
            )}
        </Attention>
    );
};

export default OutOfStockNotification;
