import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import get from 'lodash.get';

import { BloomreachExpectedPageContext, getPageType } from './helpers/getPageType';
import { checkBrEnv } from './helpers/checkBrEnv';
import {
    bloomreachPixelSendCategoryView,
    bloomreachPixelSendHomepageView,
    bloomreachPixelSendOtherView,
} from './sendBloomreachPageViewTrackingEvent';
import { brScriptSrc } from './config/brscript.config';

interface Props {
    path: string;
    pageContext: BloomreachExpectedPageContext;
    pageData: any;
}

/**
 * Bloomreach tracking pixel
 * We need to load the pixel script and then update the data on the window object
 * when we change path dymaically we need to update the data and then send it back to bloomreach.
 * @param param0
 * @returns
 */
const BloomreachPixel = ({ path, pageContext, pageData }: Props) => {
    const isBloomreachPixelEnabled = checkBrEnv();
    const pageType = useMemo(() => getPageType(path, pageContext), [path, pageContext]);

    /**
     * Listen to the page type change
     * If the page type is product then we do not want to send the page view tracking we will do that manually in treatment selector
     * ignore the order-confirmed page because we do that on the page itself with the conversion data.
     * @returns
     */
    useEffect(() => {
        if (!isBloomreachPixelEnabled) return;
        if (path === '/order-confirmed') return;
        if (path === '/search-results') return; // we do this on the page itself for bespoke functionality
        if (pageType === 'product') return;

        if (pageType === 'category' && !path.includes('request-consultation')) {
            bloomreachPixelSendCategoryView({
                cat: `home|${window.location.pathname.replace('/', '')}`,
                cat_id: get(pageData, 'condition.entry.conditionSystemIds[0].systemid')!,
            });
            return;
        }

        if (pageType === 'homepage') {
            bloomreachPixelSendHomepageView();
            return;
        }

        bloomreachPixelSendOtherView();
    }, [path, pageType]); // eslint-disable-line

    if (!isBloomreachPixelEnabled) return null;

    return (
        <Helmet>
            <link rel="preconnect" href={brScriptSrc} />
        </Helmet>
    );
};

export default BloomreachPixel;
