import { ProductVariant } from '../../../../../../../../types/api/products/Product';

export type InitialVariant = ProductVariant['id'];

/**
 * Filters variants by stock. If there are no variants with stock, return all variants regardless of stock.
 */
export const filterVariantsByStock = (urlParams: URLSearchParams, variants: ProductVariant[]) => {
    // Checks if the variants should be filtered by stock (fbs).
    if (urlParams.get('fbs') === '0') {
        return variants;
    }

    const variantsWithStock = variants.filter((variant) => variant.quantities.length > 0);
    return variantsWithStock.length ? variantsWithStock : variants;
};

/**
 * Gets the variant ID from the url. If the variant ID is not a number, return null.
 */
export const getUrlVariantId = (urlParams: URLSearchParams) => {
    const urlVariantId = parseInt(urlParams.get('variant') || '');
    return !Number.isNaN(urlVariantId) ? urlVariantId : null;
};

/**
 * Gets the default variant from a list of variants.
 */
const getDefaultVariant = (variants: ProductVariant[], initialVariant?: InitialVariant) => {
    const searchUrl = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(searchUrl);

    const filteredVariants = filterVariantsByStock(urlParams, variants);

    // Returns a variant that matches the variant ID that is provided in the function params.
    if (initialVariant) {
        const urlVariant = filteredVariants.find(({ id }) => id === initialVariant);
        if (urlVariant) {
            return urlVariant;
        }
    }

    // Returns a variant that matches the variant ID that is provided in the URL.
    const urlVariantId = getUrlVariantId(urlParams);
    if (urlVariantId) {
        const urlVariant = filteredVariants.find(({ id }) => id === urlVariantId);
        if (urlVariant) {
            return urlVariant;
        }
    }

    // Returns a variant that matches the default that is provided in the URL.
    const urlDefaultVariant = urlParams.get('default');
    if (urlDefaultVariant) {
        const urlVariant = filteredVariants.find((variant) => variant.public.slug === urlDefaultVariant);
        if (urlVariant) {
            return urlVariant;
        }
    }

    // Returns a variant that is marked as default. If there is no default variant, return the first variant.
    return filteredVariants.find((variant) => variant.default) || filteredVariants[0];
};

export default getDefaultVariant;
