import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import BaseIcon from '../../../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import IconChevronDown from '../../../_ui/_blocks/Icons/Navigation/IconChevronDown';
import MainNavigationDropdown from './MainNavigationDropdown/MainNavigationDropdown';
import Megamenu from './Megamenu/Megamenu';
import CraftMenuLink from '../../CraftMenuLink/CraftMenuLink';
import Container from '../../../_ui/_blocks/Container/Container';

import * as styles from './MainNavigation.module.css';

const MainNavigation = () => {
    const data = useStaticQuery(graphql`
        {
            craftcms {
                nodes: nodes(navHandle: "mainNavigation", level: 1) {
                    ...mainNavigation
                }
            }
            allCondition {
                nodes {
                    treatmentCount
                    computed {
                        relatedTreatmentCount
                    }
                    entry {
                        slug
                    }
                    title
                }
            }
        }
    `);

    const {
        allCondition,
        craftcms: { nodes },
    } = data;

    const conditions = allCondition.nodes.map((n: any) => ({
        ...n.entry,
        treatmentCount: n.treatmentCount,
        relatedTreatmentCount: n.computed.relatedTreatmentCount,
    })); // remap nodes.entry to just entry

    return (
        <nav className={`_js-nav-menu ${styles.nav}`} aria-label="Top Navigation">
            <Container as="ul" size="large" className="_js-nav-menu flex relative">
                {nodes &&
                    nodes.map((node: any) => {
                        let hasMegamenu =
                            node.children && node.children.length && node.classes && node.classes.indexOf('_has-megamenu') > -1;
                        /**
                         * TODO: Remove this is a temporary fix to add a class to the brands menu item. Craft doesn't have all the data in the menu yet.
                         */
                        if (node.title === 'Brands') {
                            hasMegamenu = true;
                            Object.assign(node, { classes: '_has-megamenu--brands' });
                        }

                        const hasDropdown = node.children && node.children.length && !hasMegamenu; // only show this if we have children and no mega menu

                        return (
                            <li key={node.title} className={styles.listItem}>
                                {hasDropdown ? (
                                    <Fragment>
                                        <CraftMenuLink
                                            menuNode={node}
                                            className={`cursor-pointer ${styles.link}`}
                                            activeClassName={styles.linkActive}
                                        >
                                            {node.title}
                                            <BaseIcon size="xsmall">
                                                <IconChevronDown />
                                            </BaseIcon>
                                        </CraftMenuLink>
                                        <MainNavigationDropdown node={node} />
                                    </Fragment>
                                ) : null}
                                {hasMegamenu ? (
                                    <Megamenu
                                        node={node}
                                        conditions={conditions}
                                        className={styles.link}
                                        activeClassName={styles.linkActive}
                                    />
                                ) : null}
                                {!hasDropdown && !hasMegamenu ? (
                                    <CraftMenuLink menuNode={node} className={styles.link} activeClassName={styles.linkActive}>
                                        {node.title}
                                    </CraftMenuLink>
                                ) : null}
                            </li>
                        );
                    })}
            </Container>
        </nav>
    );
};

export default MainNavigation;
