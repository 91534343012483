/** Environment vars come through as strings. */
const convertStrToBool = (str) => str === 'true';

const SITE_URL = process.env.GATSBY_SITE_URL || '';
const SITE_ORIGIN = process.env.GATSBY_SITE_ORIGIN || '';

/**
 * ------------------------------------------
 *
 * Mock Service Worker environment variables
 *
 * ------------------------------------------
 */

/** Turns mocks on for us. */
const USE_MOCKS = convertStrToBool(process.env.GATSBY_USE_MOCKS) || false;

/** Force all urls to be msw. */
const FORCE_ALL_MSW = convertStrToBool(process.env.GATSBY_FORCE_ALL_MSW) || false;

/** The url we will use for mocking requests */
const MSW_URL = process.env.GATSBY_MSW_URL;

const ENV = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

// The environment that the current code is being deployed to: development, staging or live.
const getDeploymentEnvironment = () => process.env.GATSBY_DEPLOYMENT_ENVIRONMENT || 'development';

const CHECKOUT_PUBLIC_KEY = process.env.GATSBY_CHECKOUT_PUBLIC_KEY;

/**
 * ------------------------------------------
 *
 * Tracking config
 *
 * ------------------------------------------
 */
const TRACKING_ENABLED = convertStrToBool(process.env.GATSBY_ALL_TRACKING_ENABLED);
const isTrackingEnabled = () => TRACKING_ENABLED;

const LOG_TRACKING = convertStrToBool(process.env.GATSBY_LOG_TRACKING);

const GOOGLE_ANALYTICS_ENABLED = convertStrToBool(process.env.GATSBY_GOOGLE_ANALYTICS_ENABLED);
const isGoogleAnalyticsEnabled = () => GOOGLE_ANALYTICS_ENABLED;

// Awin analytics documentation: https://wiki.awin.com/index.php/Awin_Access_Technical_Integration_Developer_Guide_(sale).
const AWIN_ENABLED = convertStrToBool(process.env.GATSBY_AWIN_ENABLED);
const isAwinEnabled = () => AWIN_ENABLED;

const AWIN_ACCOUNT_ID = process.env.GATSBY_AWIN_ACCOUNT_ID || '';
const AWIN_TEST_MODE = (() => {
    // Convert the test mode enabled to a number.
    const testModeEnabled = parseInt(process.env.GATSBY_AWIN_TEST_MODE, 10);

    // If the value is not a number or is not 1 or 0, return 1.
    if (Number.isNaN(testModeEnabled) || ![0, 1].includes(testModeEnabled)) {
        return 1;
    }

    // Returns 1 or 0;
    return testModeEnabled;
})();

const isBloomreachEnabled = () => convertStrToBool(process.env.GATSBY_BLOOMREACH_ENABLED);

const VWO_ENABLED = convertStrToBool(process.env.GATSBY_VWO_ENABLED);

const ADVANCED_ECOM_ENABLED = convertStrToBool(process.env.GATSBY_ADVANCED_ECOM_ENABLED);

const REVIEWS_IO_STORE = process.env.GATSBY_REVIEWS_IO_STORE || '';

/** Craft API url */
const CRAFT_API_URL = process.env.GATSBY_CRAFT_API_URL;
const CRAFT_URL = CRAFT_API_URL.replace('/api', '');

/** Sentry */
const SENTRY_DSN = process.env.GATSBY_SENTRY_DSN || false;
const SENTRY_ENVIRONMENT = process.env.GATSBY_SENTRY_ENVIRONMENT || 'production';
const SENTRY_SAMPLE_RATE = process.env.GATSBY_SENTRY_SAMPLE_RATE || 1;

/**
 * Default axios request api url.
 * if we are in testing environment this will force all urls to be msw enabled.
 * if we use FORCE_ALL_MSW this will force all urls to be msw enabled.
 */
const API_URL = ENV === 'test' || ENV === 'testing' || FORCE_ALL_MSW === true ? MSW_URL : process.env.GATSBY_API_URL;

/** Does what it says on the tin. */
const isBrowser = () => typeof window !== 'undefined';

/** we have a lot of mock environment variables so here are some helpers to help what's going on */
const areMocksEnabled = ENV === 'test' || ENV === 'testing' || FORCE_ALL_MSW === true;
const areMocksForced = !!FORCE_ALL_MSW;

const GATSBY_DELIVERY_NOTICE = convertStrToBool(process.env.GATSBY_DELIVERY_NOTICE); // notice shows up in the checkout.

/**
 * Check if Web Workers are available.
 */
const isWebWorkerDefined = typeof Worker !== 'undefined';

module.exports = {
    ENV,

    SITE_URL,
    SITE_ORIGIN,

    USE_MOCKS,
    FORCE_ALL_MSW,
    MSW_URL,

    CHECKOUT_PUBLIC_KEY,

    TRACKING_ENABLED,
    LOG_TRACKING,
    GOOGLE_ANALYTICS_ENABLED,
    AWIN_ENABLED,
    AWIN_ACCOUNT_ID,
    AWIN_TEST_MODE,
    VWO_ENABLED,
    ADVANCED_ECOM_ENABLED,
    isBloomreachEnabled,

    getDeploymentEnvironment,

    GATSBY_DELIVERY_NOTICE,

    REVIEWS_IO_STORE,

    CRAFT_API_URL,
    CRAFT_URL,

    SENTRY_DSN,
    SENTRY_ENVIRONMENT,
    SENTRY_SAMPLE_RATE,

    API_URL,

    isBrowser,
    areMocksEnabled,
    areMocksForced,

    isWebWorkerDefined,
    isTrackingEnabled,
    isAwinEnabled,
    isGoogleAnalyticsEnabled,
    convertStrToBool,
};
