import React, { Fragment } from 'react';

import useManageBasketItems from '../../../../../../data/context/basketContext/hooks/useManageBasketItems';
import useTreatmentImage from '../../../../../../data/staticQueryHooks/useTreatmentImage';
import { DeleteCardOverlay, DeleteCardOverlayTrigger } from '../../../../../_ui/Card/DeleteCardOverlay/DeleteCardOverlay';
import { BasketItem } from '../../../../../../types/api/basket/BasketItem';

import Typography from '../../../../../_ui/_blocks/Typography/Typography';
import QuantityButton from '../QuantityButton/QuantityButton';

import * as styles from '../../BasketItem.module.css';

export interface Props {
    item: BasketItem;
    openQuantitySelector: (args?: any) => void;
}

const DefaultBasketItem = ({ item, openQuantitySelector }: Props) => {
    const { getTreatmentImage } = useTreatmentImage();
    const { deleteItems } = useManageBasketItems();

    // Deletes the basket item.
    const handleDeleteItem = () => deleteItems([{ id: item.id }]);

    // UI elements for return render.
    const treatmentImage = getTreatmentImage(item.reference) || '/images/pill.svg';
    const total = ((item.total || 0) / 100).toFixed(2);
    const deleteDescription = 'Are you sure? This will also delete the consultation for this treatment.';

    return (
        <DeleteCardOverlay className={styles.item} title={item.treatment} description={deleteDescription} onDelete={handleDeleteItem}>
            <Fragment>
                <div data-testid="default_basket_item" className="flex items-start gap-100">
                    <img width={52} height={52} className={styles.image} src={treatmentImage} alt={item.treatment} />
                    <div className="flex-grow space-y-050">
                        <Typography as="h6" typeset="heading" size="080">
                            {item.treatment}
                        </Typography>
                        <div className="flex justify-between gap-100">
                            <QuantityButton onClick={openQuantitySelector} item={item} />
                            <Typography as="span" typeset="heading" size="080" color="price">
                                &pound;{total}
                            </Typography>
                        </div>
                    </div>
                </div>
                <DeleteCardOverlayTrigger title={`Remove ${item.treatment} from your basket`} />
            </Fragment>
        </DeleteCardOverlay>
    );
};

export default DefaultBasketItem;
