import React from 'react';
import loadable from '@loadable/component';

export const iconList = {
    'icon-medicine': loadable(() => import('../IconMedicine')),
    'icon-clipboard': loadable(() => import('../IconClipboard')),
    'icon-advice': loadable(() => import('../IconAdvice')),
    'icon-doctor': loadable(() => import('../IconDoctor')),
    'icon-medical-cross': loadable(() => import('../IconMedicalCross')),
    'icon-phone': loadable(() => import('../IconPhone')),
    'icon-order': loadable(() => import('../IconOrder')),
    'icon-circle-plus': loadable(() => import('../Status/IconCirclePlus')),
    'icon-status-help': loadable(() => import('../Status/IconStatusHelp')),
    'icon-status-info': loadable(() => import('../Status/IconStatusInfo')),
    'icon-status-success': loadable(() => import('../Status/IconStatusSuccess')),
    'icon-status-warning': loadable(() => import('../Status/IconStatusWarning')),
    'icon-status-error': loadable(() => import('../Status/IconStatusWarning')),
};

/**
 * Note:
 * Only use this for icons which are dynamically set.
 * Use staticly imported icons where possible.
 *
 * TODO: This wont work if it's undefined, should do an earlier escape return.
 */
const findIcon = (key) => {
    const Icon = iconList[key];

    if (!Icon) {
        console.warn(`icon "${key}" not found`);
        return null;
    }

    return <Icon />;
};

export default findIcon;
