import React, { Fragment } from 'react';

const IconBasket = () => (
    <Fragment>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3697 21.6173C10.8877 21.3436 10.7156 20.7253 10.9853 20.2363L18.9327 5.8243C19.2024 5.33525 19.8117 5.16063 20.2937 5.43428C20.7757 5.70793 20.9477 6.32623 20.6781 6.81528L12.7306 21.2272C12.461 21.7163 11.8516 21.8909 11.3697 21.6173Z"
            fill="#56E7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.3092 21.6173C36.7911 21.3436 36.9632 20.7253 36.6935 20.2363L28.7461 5.8243C28.4765 5.33525 27.8671 5.16063 27.3852 5.43428C26.9032 5.70793 26.7311 6.32623 27.0008 6.81528L34.9482 21.2272C35.2179 21.7163 35.8272 21.8909 36.3092 21.6173Z"
            fill="#56E7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.90884 29.4484C1.90884 28.8257 2.37368 28.3209 2.94707 28.3209H45.0531C45.6265 28.3209 46.0913 28.8257 46.0913 29.4484C46.0913 30.0711 45.6265 30.5758 45.0531 30.5758H2.94707C2.37368 30.5758 1.90884 30.0711 1.90884 29.4484Z"
            fill="#56E7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.01397 37.4929C4.01397 36.8703 4.4788 36.3655 5.0522 36.3655H42.9476C43.521 36.3655 43.9858 36.8703 43.9858 37.4929C43.9858 38.1156 43.521 38.6204 42.9476 38.6204H5.0522C4.4788 38.6204 4.01397 38.1156 4.01397 37.4929Z"
            fill="#56E7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.5368 22.9477H3.46323C2.99513 22.9477 2.66202 23.4418 2.8034 23.9264L8.50526 43.4689C8.59639 43.7812 8.86336 43.9935 9.16508 43.9935H38.8349C39.1366 43.9935 39.4036 43.7812 39.4947 43.4689L45.1966 23.9264C45.338 23.4418 45.0049 22.9477 44.5368 22.9477ZM3.46323 19.9412C1.12273 19.9412 -0.54283 22.4116 0.164099 24.8345L5.86595 44.377C6.32163 45.9387 7.65644 47 9.16508 47H38.8349C40.3436 47 41.6784 45.9387 42.134 44.377L47.8359 24.8345C48.5428 22.4116 46.8773 19.9412 44.5368 19.9412H3.46323Z"
            fill="#56E7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4883 20.3973C12.0578 20.3249 12.5904 20.765 12.6779 21.3804L16.0786 45.2988C16.1661 45.9142 15.7754 46.4717 15.2059 46.5441C14.6364 46.6165 14.1037 46.1763 14.0163 45.561L10.6155 21.6426C10.528 21.0272 10.9187 20.4697 11.4883 20.3973Z"
            fill="#56E7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.0883 20.3973C35.5188 20.3249 34.9862 20.765 34.8987 21.3804L31.4979 45.2988C31.4104 45.9142 31.8012 46.4717 32.3707 46.5441C32.9402 46.6165 33.4728 46.1763 33.5603 45.561L36.9611 21.6426C37.0486 21.0272 36.6578 20.4697 36.0883 20.3973Z"
            fill="#56E7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 20.2764C24.5734 20.2764 25.0382 20.7812 25.0382 21.4038V45.5374C25.0382 46.1601 24.5734 46.6648 24 46.6648C23.4266 46.6648 22.9617 46.1601 22.9617 45.5374V21.4038C22.9617 20.7812 23.4266 20.2764 24 20.2764Z"
            fill="#56E7FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3201 12.6402C15.1955 12.0017 14.794 10.559 15.4233 9.41791L19.3969 2.21193C20.0262 1.07081 21.448 0.663375 22.5726 1.30189C23.6971 1.94041 24.0987 3.38309 23.4694 4.52421L19.4957 11.7302C18.8665 12.8713 17.4447 13.2787 16.3201 12.6402Z"
            fill="#016EB7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.3589 12.6402C32.4835 12.0017 32.885 10.559 32.2558 9.41791L28.2821 2.21193C27.6528 1.07081 26.231 0.663375 25.1064 1.30189C23.9819 1.94041 23.5803 3.38309 24.2096 4.52421L28.1833 11.7302C28.8126 12.8713 30.2343 13.2787 31.3589 12.6402Z"
            fill="#016EB7"
        />
    </Fragment>
);

export default IconBasket;
