/* eslint-disable camelcase */
import get from 'lodash.get';

import { affiliateInScope } from '../../../affiliate/helpers/affiliateInScope';
import { getAffiliateJwtDecoded } from '../../../affiliate/helpers/getAffiliateJwt';
import ProductTypes from '../../../../types/api/products/ProductTypes';
import ConsultationTypes from '../../../../types/api/Consultation/ConsultationTypes';
import { BasketItem } from '../../../../types/api/basket/BasketItem';

interface AnalyticsProduct {
    name: string;
    id: number;
    category: string;
    variant: string;
    price: string;
    quantity: number; // Number of items ordered
    dimension2: 'Logged in';
    dimension3: ProductTypes;
    dimension4: string;
    dimension5: ConsultationTypes;
    dimension15: string | null;
    boltOn: boolean;
}

interface Checkout {
    actionField: {
        step: 1;
    };
    products: AnalyticsProduct[];
}

interface ReturnType {
    ecommerce: {
        checkout: Partial<Checkout>;
    };
}

/**
 * Formats analytics data when in the checkout delivery section.
 * @param {BasketItem[]} basketItems - The items in the basket.
 * @param {boolean} shouldIncludeProducts - If basket items should be added to the analytics.
 * @returns {ReturnType}
 */
const formatCheckoutDeliveryAnalytics = (basketItems: BasketItem[], shouldIncludeProducts: boolean): ReturnType => {
    const dataToPush: ReturnType = {
        ecommerce: {
            checkout: {
                actionField: {
                    step: 1,
                },
            },
        },
    };

    if (shouldIncludeProducts) {
        const affiliateCode = get(getAffiliateJwtDecoded({}), 'affiliate_code') || null;

        const products: AnalyticsProduct[] = basketItems.map((basketItem) => {
            // eslint-disable-line arrow-body-style
            const { product_name, reference, condition_name, treatment, unit_cost, quantity, is_default_variant, type, order_type } =
                basketItem;

            const isDefaultVariant = is_default_variant ? 'Default Variant' : 'Not Default Variant';

            return {
                name: product_name, // The parent/product name
                id: reference as number, // The treatment reference
                category: condition_name, // The product category
                variant: treatment, // Name of the variant
                price: (unit_cost / 100).toFixed(2), // TODO: The totoal price of the item?
                quantity, // Number of items ordered
                dimension2: 'Logged in',
                dimension3: type.toUpperCase() as ProductTypes, // PRODUCT, PMED, ODONLY
                dimension4: isDefaultVariant, // If the variant is the default.
                dimension5: order_type,

                dimension15: affiliateInScope(reference as number) ? affiliateCode : null, // If there is an affiliate code applied
                boltOn: !!basketItem.boltOn,
            };
        });

        dataToPush.ecommerce!.checkout.products = products;
    }

    return dataToPush;
};

export default formatCheckoutDeliveryAnalytics;
