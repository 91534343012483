import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
import { genericHashLink } from 'react-router-hash-link';
import debounce from 'lodash.debounce';

import { useAuthContext } from '../../../data/context/authContext';

import IconProfileLight from '../../_ui/_blocks/Icons/IconProfileLight';
import Typography from '../../_ui/_blocks/Typography/Typography';
import BaseIcon from '../../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import Button from '../../_ui/_blocks/Buttons/Button/Button';

import * as styles from './AccountDropdown.module.css';
import * as dropdownStyles from '../CraftMenuTypes/MainNavigation/MainNavigationDropdown/MainNavigationDropdown.module.css';

interface Props {
    disableAccountDropdownOnMobile?: boolean;
}

const AccountDropdown = ({ disableAccountDropdownOnMobile = false }: Props) => {
    const { isLoggedIn, logout, patientName } = useAuthContext();
    const [shouldPinToRight, setShouldPinToRight] = useState(false);
    const dropdownRef = useRef<HTMLUListElement>(null);

    const signInText = isLoggedIn ? `Hello ${patientName || 'user'}` : 'Sign in';
    const signInLink = isLoggedIn ? '/account' : '/login';

    const ScrollLink = genericHashLink(Link);

    // Can't pass logout directly to the button as the redirect param will be passed as an event object.
    const handleLogout = () => logout();

    const hideOnMobileClassName = disableAccountDropdownOnMobile ? styles.listItemMobileHidden : undefined;

    useEffect(() => {
        const handleResize = () => {
            const dropdownElement = dropdownRef.current;
            if (dropdownElement) {
                const rect = dropdownElement.getBoundingClientRect();
                const menuWidth = rect.width - 120;
                const menuRight = rect.right;
                const distanceFromEdge = window.innerWidth - menuRight;

                if (menuWidth > distanceFromEdge) {
                    setShouldPinToRight(true);
                } else {
                    setShouldPinToRight(false);
                }
            }
        };

        const handleResizeDebounced = debounce(handleResize, 1000);

        window.addEventListener('resize', handleResizeDebounced);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResizeDebounced);
            handleResizeDebounced.cancel();
        };
    }, []);

    return (
        <div className={`${styles.listItem} ${hideOnMobileClassName}`}>
            <Link
                to={signInLink}
                aria-label="Your Independent Pharmacy account"
                title="Your Independent Pharmacy account"
                className={styles.link}
            >
                <Typography typeset="heading" size="100" color="white">
                    {signInText}
                </Typography>
                <BaseIcon>
                    <IconProfileLight />
                </BaseIcon>
            </Link>
            {isLoggedIn ? (
                <ul className={`${dropdownStyles.dropdown} ${shouldPinToRight ? styles.pinnedToRight : ''}`} ref={dropdownRef}>
                    <li>
                        <Link to="/account" className={dropdownStyles.dropdownItem}>
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <ScrollLink to="/account/orders#order-history" className={dropdownStyles.dropdownItem}>
                            Your orders
                        </ScrollLink>
                    </li>
                    <li>
                        <Link to="/account/orders/treatment-reorders" className={dropdownStyles.dropdownItem}>
                            Treatment reorders
                        </Link>
                    </li>
                    <li className={styles.border} />
                    <li>
                        <Button
                            variant="none"
                            size="none"
                            title="Sign out of your account"
                            onClick={handleLogout}
                            className={`!w-full ${dropdownStyles.dropdownItem}`}
                        >
                            Sign out
                        </Button>
                    </li>
                </ul>
            ) : null}
        </div>
    );
};

export default AccountDropdown;
