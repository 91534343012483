import React from 'react';

import { useSidebarNavigationUpdaterContext } from '../../data/context/sidebarNavigationContext';
import { useSearchContext } from '../../data/context/searchContext';
import { emitNavigationOpenEvent } from '../../hooks/useNavigationCloseHook';

import BaseIcon from '../_ui/_blocks/Icons/BaseIcon/BaseIcon';
import IconMenu from '../_ui/_blocks/Icons/Navigation/IconMenu';
import IconSearch from '../_ui/_blocks/Icons/Navigation/IconSearch';
import Button from '../_ui/_blocks/Buttons/Button/Button';
import SignInButton from './SignInButton/SignInButton';

import * as styles from './DrawerNavigation.module.css';

const DrawerNavigation = () => {
    const { setShowOverlay } = useSearchContext();
    const setSidebarNavIsOpen = useSidebarNavigationUpdaterContext();

    const openSearch = () => {
        setShowOverlay(true);
    };

    const openMobileMenu = () => {
        emitNavigationOpenEvent('mobile');
        setSidebarNavIsOpen(true);
    };

    return (
        <nav className={styles.drawer} aria-label="Nav Navigation" data-visual-test="transparent">
            <Button
                variant="none"
                size="none"
                className={`_js-draw-nav-search ${styles.button}`}
                title="Open search bar"
                aria-label="Open search bar"
                onClick={openSearch}
            >
                Search
                <BaseIcon>
                    <IconSearch />
                </BaseIcon>
            </Button>
            <SignInButton />
            <Button
                variant="none"
                size="none"
                className={styles.button}
                aria-label="Open the main menu"
                title="Open the main menu"
                onClick={openMobileMenu}
            >
                Menu
                <BaseIcon color="accent">
                    <IconMenu />
                </BaseIcon>
            </Button>
        </nav>
    );
};

export default DrawerNavigation;
