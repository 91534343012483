/**
 * This file is still in use where there is a problem
 * with the stacking order of the rendered HTML.
 * @see https://app.clickup.com/t/8693p5n5t
 */
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import * as styles from './Overlay.module.css';

interface Props {
    className?: string;
    fixedToViewport?: boolean;
    show: boolean;
    onHide?: () => void;
    children: React.ReactNode | null;
}

const Overlay = ({ className = '', fixedToViewport = true, show, onHide, children }: Props) => (
    <CSSTransition
        mountOnEnter
        unmountOnExit
        in={show}
        appear={show}
        timeout={{ enter: 600, exit: 300 }}
        className={`${styles.overlay} ${className} ${fixedToViewport ? styles.fixed : ''}`}
        classNames={{
            enterActive: styles.animateIn,
            enterDone: styles.animateIn,
            exit: styles.animateOut,
        }}
        onExited={onHide}
    >
        <div>{children}</div>
    </CSSTransition>
);

export default Overlay;
