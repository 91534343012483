import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import clx from 'classnames';

import { useCookieWatcher } from '../../helpers/cookie';
import { DEFAULT_FLASH_MESSAGE_KEY, FLASH_EXPIRES_IN } from './config/config';

import Flash, { Types } from './Flash/Flash';

import * as styles from './FlashMessage.module.css';

interface Props {
    cookieKey: string;
    type?: keyof typeof Types;
    important?: boolean;
    className?: string;
}

const FlashMessage = ({ type = 'info', important = false, cookieKey = DEFAULT_FLASH_MESSAGE_KEY, className }: Props) => {
    const [message, forceCookieRemove] = useCookieWatcher(cookieKey);

    const timeout = useRef<NodeJS.Timeout | undefined>();

    const classNames = clx(styles.flashMessage, className);

    useEffect(() => {
        timeout.current = setTimeout(() => forceCookieRemove(), FLASH_EXPIRES_IN * 60 * 1000);

        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        };
    }, []);

    const handleClose = () => {
        forceCookieRemove();
    };

    if (!message) {
        return null;
    }

    return createPortal((
        <div className={classNames}>
            <Flash type={type} important={important} message={message} onClose={handleClose} />
        </div>
    ), document.body, 'flash-message');
};

export default FlashMessage;
