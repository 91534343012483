import React, { Fragment, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import clx from 'classnames';

import LoadingIcon from '../Icons/LoadingIcon/LoadingIcon';
import Typography from '../Typography/Typography';

import * as styles from './Loading.module.css';

export enum Types {
    fullScreen = styles.loadingFullScreen,
    inline = styles.loadingInline,
    none = '',
}

interface Props {
    isLoading: boolean;
    type?: keyof typeof Types;
    iconClassName?: string;
    children?: ReactNode;
    message?: ReactNode;
}

const Loading = ({ isLoading, type = 'none', iconClassName, children, message }: Props) => {
    const classnames = clx(styles.loading, 'space-y-050', Types[type], iconClassName);

    return (
        <CSSTransition timeout={0} in appear mountOnEnter unmountOnExit>
            {isLoading ? (
                <div data-testid="loading-icon" className={classnames}>
                    <LoadingIcon className="block mx-auto" />
                    {message ? (
                        <Typography color="quiet" className="block text-center">
                            {message}
                        </Typography>
                    ) : null}
                </div>
            ) : (
                // Fragment is required as multiple children can be passed in.
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <Fragment>{children}</Fragment>
            )}
        </CSSTransition>
    );
};

export default Loading;
